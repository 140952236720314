import { useState } from 'react';
import { Collapse, Modal } from 'react-bootstrap';

import Editable from '../Icons/Editable';
import ExclamationTriangle from '../Icons/ExclamationTriangle';
import Uneditable from '../Icons/Uneditable';

type ScopeToggleProps = {
    value: boolean,
    editable: boolean,
    onChange: (value: boolean) => void,
};

const ScopeToggle = ({ value, onChange, editable }: ScopeToggleProps) => {

    const [boxChecked, setBoxChecked] = useState<boolean>(value);
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

    const handleButtonClick = () => {
        if (onChange) {
            onChange(!boxChecked);
        }
        setBoxChecked(!boxChecked);
    }

    const lightGray = '#ADB5BD';
    const bootstrapWarning = '#FFC107';

    // the id='rule-scope-check connects a specific .css rule to this specific div
    return (
        <div className='row align-items-center'>
            <Modal
                className='modal'
                show={showInfoModal}
                onHide={() => setShowInfoModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='data-source-help-modal'>
                        Saving Global Projects
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>
                            When 'Project Specific' is unchecked: Rules that are currently in the data base for any Project will be removed and a new entry will be added under Dev V.03.
                        </p>
                        <p>
                            It will then be available under the Project Selector under '003 - Dev V.03'
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
            <div id='rule-scope-check' className='col-11'>
                <input
                    id='scopeSwitch'
                    className='form-check-input'
                    type='checkbox'
                    checked={boxChecked}
                    onClick={handleButtonClick}
                    disabled={!editable}
                />
                <label
                    className='form-check-label'
                    title='Unchecking this causes the project to use only the Dev V.03 rules, and will cause the saved data source to be pushed to all active and warranty active projects'
                >
                &nbsp;Project Specific</label>
            </div>
            <div className='col-1 text-center'
            >
                {
                    !boxChecked &&
                    <div
                        className='row'
                    >
                        <div
                            className='col'
                            title='Caution!  Data updates will be applied to all projects when saving Data Sources.  Click for more information.'
                            onClick={() => setShowInfoModal(true)}
                        >
                        <ExclamationTriangle size='20' color={editable ? bootstrapWarning : lightGray} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

type RuleScopeDisplayProps = {
    value: boolean,
    editable: boolean,
    onChange: (value: boolean) => void,
};

const RuleScopeDisplay = ({ value, onChange, editable }: RuleScopeDisplayProps) => {

    const [editing, setEditing] = useState<boolean>(false);
    const [collapse, setCollapse] = useState<boolean>(true);

    // used to ensure that the editability is managed in a way that is consistent
    const toggleCollapse = () => {
        if(!collapse) {
            setEditing(false);
        }
        setCollapse(!collapse);
    };

    // used to ensure that the editability is managed in a way that is consistent
    const toggleEditing = () => {
        if(!editing) {
            setCollapse(false);
        }
        setEditing(!editing);
    };

    // used to simplify a className callout for the edit button
    const hideButton = (hide: boolean) => {
        return (hide ? 'visually-hidden' : '');
    }

    const handleChange = (newValue: boolean) => {
        if (onChange) {
            onChange(newValue);
        }
    }

    return (
        <div
          className='border shadow rounded-2 permissions-tile'
        >
            <div className='ps-3 pt-2'>
                <b>
                    Rule Scope
                </b>
            </div>
            <div>
                <div className='col border p-2 m-2 shadow-sm editable-list-tile rounded-2'>
                    <div className='row'>
                        <div className='row d-flex align-items-center'>
                            <div className='col-11' onClick={() => {toggleCollapse()}}>
                                <b>Scope</b>
                            </div>
                            <div className='col-1 d-flex justify-content-center'>
                                <button
                                    className={`btn ${hideButton(!editable)}`}
                                    title={editing ? 'click to stop editing' : 'click to allow editing'}
                                    disabled={!editable}
                                    onClick={() => {toggleEditing()}}
                                >
                                    {editing ? <Editable /> : <Uneditable />}
                                </button>
                            </div>
                        </div>
                    </div>
                    <Collapse
                        in={!collapse}
                    >
                        <div>
                            <div
                                className='row border shadow-sm m-2 p-2 editable-list rounded-2'
                            >
                                <ScopeToggle
                                    value={value}
                                    onChange={handleChange}
                                    editable={editing && editable}
                                />
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    )
};

export default RuleScopeDisplay;