type Props = {
    scale: number
} & typeof defaultProps;

const defaultProps = {
    scale: 1
}

const color = 'dimgrey'
const rate = '3s'

function calculateSize(defaultSize: number, inputScale: number) {
    var size = defaultSize * inputScale
    return size.toString()
}

function calculateTransform(defaultSize: number, inputScale: number) {
    var number = calculateSize(defaultSize, inputScale)
    var transform = `translate(${number}, ${number})`
    return transform
}

const WindTurbineSpinner = ({ scale }: Props) => (
    <svg width={calculateSize(100, scale)} height={calculateSize(100, scale)}>
       <g>
           <rect id='nacelle' x={calculateSize(45, scale)} y={calculateSize(45, scale)} height={calculateSize(10, scale)} width={calculateSize(10, scale)} stroke={color} strokeWidth={calculateSize(1, scale)} fill='white'/>
           <rect x={calculateSize(48, scale)} y={calculateSize(50, scale)} height={calculateSize(50, scale)} width={calculateSize(4, scale)} stroke={color} strokeWidth={calculateSize(1, scale)} fill="white" />
       </g>
       <g id="turbine" transform={calculateTransform(50, scale)}>
            <ellipse cx={calculateSize(-15, scale)} cy={calculateSize(0, scale)} rx={calculateSize(15, scale)} ry={calculateSize(2, scale)} stroke={color} strokeWidth={calculateSize(1, scale)} fill="white" transform="rotate(0, 0, 0)" />
            <ellipse cx={calculateSize(-15, scale)} cy={calculateSize(0, scale)} rx={calculateSize(15, scale)} ry={calculateSize(2, scale)} stroke={color} strokeWidth={calculateSize(1, scale)} fill="white" transform="rotate(120, 0, 0)" />
            <ellipse cx={calculateSize(-15, scale)} cy={calculateSize(0, scale)} rx={calculateSize(15, scale)} ry={calculateSize(2, scale)} stroke={color} strokeWidth={calculateSize(1, scale)} fill="white" transform="rotate(240, 0, 0)" />
            <circle cx={calculateSize(0, scale)} cy={calculateSize(0, scale)} r={calculateSize(4, scale)} stroke={color} strokeWidth={calculateSize(1, scale)} fill="white" />
            <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                from="0, 0, 0"
                to="360, 0, 0"
                dur={rate}
                additive="sum"
                repeatCount="indefinite"
                transform-origin="center"
            />
       </g>
    </svg>
);

WindTurbineSpinner.defaultProps = defaultProps;

export default WindTurbineSpinner;