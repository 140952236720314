import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { DeleteStatus } from '../../enums/DeleteStatus';

import '../../styles/Common.css';

type DeleteButtonProps = {
    deleteStatus: DeleteStatus,
    onClick?: () => void | undefined,
    deleteText: string,
    deletingText: string,
    deletedText: string,
    errorText: string,
};

const defaultProps = {
    deleteText: 'Delete',
    deletingText: 'Deleting',
    deletedText: 'Deleted',
    errorText: 'Error',
}

const DeleteButton = ({ deleteStatus, onClick, deleteText, deletedText, deletingText, errorText }: DeleteButtonProps) => {
    const [deleting, setdeleting] = useState<boolean>(false);

    useEffect(() => {
        if (deleteStatus === DeleteStatus.Deleting) {
            setdeleting(true);
            return
        }
        setdeleting(false);
    }, [deleteStatus]);

    const getButtonString = (status: DeleteStatus) => {
        switch(status) {
            case DeleteStatus.Undeleted:
                return deleteText;

            case DeleteStatus.Deleting:
                return deletingText;

            case DeleteStatus.Deleted:
                return deletedText;

            case DeleteStatus.Error:
                return errorText;
        }
    };

    const getButtonColoring = (status: DeleteStatus) => {
        switch(status) {
            case DeleteStatus.Undeleted:
                return 'btn-danger';

            case DeleteStatus.Deleting:
                return 'btn-outline-danger';

            case DeleteStatus.Deleted:
                return 'btn-outline-success';

            case DeleteStatus.Error:
                return 'btn-outline-warning';
        }
    };

    const handleOnClick = () => {
        if (deleteStatus === DeleteStatus.Deleted) {
            return;
        }
        if(onClick) {
            onClick();
        }
    };

    return (
        <button
            type='button'
            className={`btn data-source-btn ${getButtonColoring(deleteStatus)}`}
            onClick={handleOnClick}
        >
        {deleting && <Spinner animation='border' size='sm' role='status' aria-hidden='true' />}
            {!deleting && (getButtonString(deleteStatus))}
        </button>
    );
}

DeleteButton.defaultProps = defaultProps;

export default DeleteButton;