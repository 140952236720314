import { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import {Container, Modal, Nav, Navbar, NavDropdown,} from "react-bootstrap";

import Gear from './Icons/Gear';
import LogOut from './Icons/LogOut';
import NewTab from './Icons/NewTab';
import UserDataDisplay from "./UserDataDisplay";
import { hasRuleEditorAccess, hasAnyAdminAccess } from '../functions/AdminPageAccess';
import logo from '../images/logo.png'
import DataSourceEmployeeInfo from "../types/Employee/DataSourceEmployeeInfo";
import CirclePending from './Icons/CirclePending';

type NavbarTypes = {
    userData: DataSourceEmployeeInfo,
    gettingUserData: boolean,
};

const infoItem = (loading: boolean) => (
    <div className='row'>
        <div className='col-2'>
            { loading ? <CirclePending size='14' animate={loading} /> : <Gear size='14' /> }
        </div>
        <div className='col text-blattner-blue'>
            Info
        </div>
    </div>
);

const logOutItem = () => (
    <div className='row'>
        <div className='col-2'>
            <LogOut />
        </div>
        <div className='col text-blattner-blue'>
            Log Out
        </div>
    </div>
);

const NavbarTop = ({ userData, gettingUserData }: NavbarTypes) => {
    const { accounts, instance } = useMsal();
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [page, setPage] = useState<string>('home');

    return (
        <Navbar style={{backgroundColor: "#02568a"}} collapseOnSelect fixed="top" expand="sm" variant="dark">
            <Container>
            <Modal
                className='modal-lg'
                show={showInfoModal}
                onHide={() => setShowInfoModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='user-data-modal-header'>
                        <h3 className='ps-4 mt-2'>
                            {userData.firstName}&nbsp;{userData.lastName}&nbsp;
                            <a
                                href='/userinfo'
                                target='_blank'
                                onClick={() => setShowInfoModal(false)}
                                title='open user info page in new tab'
                            >
                                <NewTab />
                            </a>
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                      className='mb-4'
                    >
                        <UserDataDisplay userData={userData} asModal={true}/>
                    </div>
                </Modal.Body>
            </Modal>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Navbar.Brand
                        as={Link}
                        to="/"
                        onClick={() => setPage('home')}
                    >
                        <img
                            src={logo}
                            width="200"
                            height="75"
                            alt=""
                        />
                    </Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link
                            className={page === 'home' ? 'border-bottom' : ''}
                            as={Link}
                            to="/"
                            style={{color: "white"}}
                            onClick={() => setPage('home')}
                        >
                            Home
                        </Nav.Link>
                        <Nav.Link
                            className={page === 'tasks' ? 'border-bottom' : ''}
                            as={Link}
                            to="/tasks"
                            style={{color: "white"}}
                            href="#Forms"
                            onClick={() => setPage('tasks')}
                        >
                            NCR Tasks
                        </Nav.Link>
                        <NavDropdown
                            className={page === 'dotCoordinators' || page === 'qualityFormApprovers' ? 'border-bottom' : ''}
                            title="Roles"
                            id="nav-dropdown"
                        >
                            <NavDropdown.Item
                                as={Link}
                                to="/dotcoordinators"
                                onClick={() => setPage('dotCoordinators')}
                            >
                                DOT Coordinators
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                as={Link}
                                to="/qualityformapprovers"
                                onClick={() => setPage('qualityFormApprovers')}
                            >
                                Quality Form Approvers
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link
                            className={page === 'projectgoals' ? 'border-bottom' : ''}
                            as={Link}
                            to="/projectgoals"
                            style={{color: "white"}}
                            onClick={() => setPage('projectgoals')}
                        >Project Goals</Nav.Link>
                        <Nav.Link
                            className={page === 'dataEditor' ? 'border-bottom' : ''}
                            as={Link}
                            to="/dataEditor"
                            style={{color: "white"}}
                            href="#Forms"
                            onClick={() => setPage('dataEditor')}
                        >
                            Data Editor
                        </Nav.Link>
                        {
                            hasRuleEditorAccess(userData)
                            &&
                            <Nav.Link
                                className={page === 'ruleEditor' ? 'border-bottom' : ''}
                                as={Link}
                                to="/ruleEditor"
                                style={{color: "white"}}
                                href="#Forms"
                                onClick={() => setPage('ruleEditor')}
                            >
                                Rule Editor
                            </Nav.Link>
                        }
                        {
                            hasAnyAdminAccess(userData)
                            &&
                            <Nav.Link
                                className={page === 'admin' ? 'border-bottom' : ''}
                                as={Link}
                                to="/admin"
                                style={{color: "white"}}
                                href="#Forms"
                                onClick={() => setPage('admin')}
                            >
                                Admin
                            </Nav.Link>
                        }
                    </Nav>
                    <div className="WelcomeDiv" >
                        <h5>
                            <NavDropdown title={`Welcome ${userData.lastName}, ${userData.firstName}`} id="nav-dropdown">
                                <NavDropdown.Item onClick={() => setShowInfoModal(true && !gettingUserData)}>{infoItem(gettingUserData)}</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => instance.logout()}>{logOutItem()}</NavDropdown.Item>
                            </NavDropdown>
                        </h5>
                    </div>
                </Navbar.Collapse>
            </Container>
      </Navbar>
    );
}

export default NavbarTop