import { Component } from'react';
import { Modal, Spinner, Table } from 'react-bootstrap';
import { appInsights } from '../services/appInsights';
import { MsalContext } from "@azure/msal-react";
import DashboardService from '../services/DashboardService';
import IProjectList from '../types/Dashboard/ProjectList';
import IFormSubmissionList from '../types/Dashboard/FormSubmissionList'
import SelectField from '../components/SelectField';
import { Link } from 'react-router-dom';
import TextField from '../components/TextField';
import DateField from '../components/DateField';
import ButtonType from '../components/ButtonType';
import MyPagination from '../components/MyPagination';
import Delayed from '../components/Delayed';
import ProjectDropdown from '../components/ProjectDropdown';
import LoadingWrapper from '../components/LoadingWrapper';

type Props = {
    updateProject?: (e: any) => void;
}

type State = {
    projectLoading: boolean;
    selectedProject: string;
    projectList: IProjectList;
    formSubmissionsLoading: boolean;
    formSubmissionList: IFormSubmissionList;
    sort_by: string;
    sort_type: string;
    dateFilter: string;
    descriptionFilter: string;
    modifiedFilter: string;
    statusFilter: string;
    page: number;
    page_size: number;
    openFormStatusModal: boolean;
    taskUsersLoading: boolean;
    taskUsers: string[];
}

export default class Dashboard extends Component<Props, State> {
    static contextType = MsalContext;
    constructor(props: any) {
        super(props);

        const queryString = new URLSearchParams(props.location.search);

        // setup default state
        this.state = {
            projectLoading: true,
            selectedProject: "",
            projectList: {
                projects: [],
                defaultProjectID: ""
            },
            formSubmissionsLoading: false,
            formSubmissionList: {
                formSubmissions: [],
                totalCount: 0
            },
            sort_by: queryString.get("sort_by") ?? "submittedDate",
            sort_type: queryString.get("sort_type") ?? "desc",
            dateFilter: queryString.get("date") ?? "",
            descriptionFilter: queryString.get("description") ?? "",
            modifiedFilter: queryString.get("modified") ?? "",
            statusFilter: queryString.get("status") ?? "",
            page: parseInt(queryString.get("page") ?? "1"),
            page_size: parseInt(queryString.get("page_size") ?? "50"),
            openFormStatusModal: false,
            taskUsersLoading: false,
            taskUsers: []
        }
    };

    async componentDidMount() {
        appInsights.trackPageView({ name: "Dashboard Page" });
        this.getProjects();
    }

    async getProjects() {
        await DashboardService.getProjects(this.context.accounts[0].username)
            .then((response) => {
                this.setState({
                    projectLoading: false,
                    projectList: response.data
                });

                if (response.data.defaultProjectID && !this.state.selectedProject) {
                    this.setState({
                        selectedProject: response.data.defaultProjectID
                    });
                }

                if (this.state.selectedProject) {
                    this.setState({
                        formSubmissionsLoading: true
                    });

                    this.getFormSubmissions(this.state.selectedProject, this.state.sort_by, this.state.sort_type, this.state.dateFilter, this.state.descriptionFilter,
                        this.state.modifiedFilter, this.state.statusFilter, this.state.page, this.state.page_size)
                }
            })
            .catch((e) => {
                this.setState({
                    projectLoading: false
                });
            });
    }

    async getFormSubmissions(projectID: string, sort_by: string, sort_type: string, dateFilter:string, descriptionFilter: string, modifiedFilter: string,
        statusFilter: string, page: number, page_size: number) {
            await DashboardService.getFormSubmissions(projectID, sort_by, sort_type, dateFilter, descriptionFilter, modifiedFilter, statusFilter, page, page_size)
                .then((response) => {
                    this.setState({
                        formSubmissionsLoading: false,
                        formSubmissionList: response.data
                    });
                })
                .catch((e) => {
                    this.setState({
                        formSubmissionsLoading: false
                    });
                });
      }

    async getTaskUsers(entryID: string) {
        await DashboardService.getTaskUsers(entryID)
            .then((response) => {
                this.setState({
                    taskUsersLoading: false,
                    taskUsers: response.data
                });
            })
            .catch((e) => {
                this.setState({
                    taskUsersLoading: false
                });
            });
      }

    handleProjectChange = (project: string) => {
        if(this.props.updateProject) {
            this.props.updateProject(project);
        }
        this.setState({
            selectedProject: project,
            formSubmissionsLoading: true,
            page: 1
        });
        
        this.getFormSubmissions(project, this.state.sort_by, this.state.sort_type, this.state.dateFilter, this.state.descriptionFilter, this.state.modifiedFilter,
            this.state.statusFilter, 1, this.state.page_size);
      }

    handlePageSizeChange = (event: any) => {
        const pageSize = event.target.value;
        const newLastPage = Math.ceil(this.state.formSubmissionList.totalCount/pageSize);

        var currentPage = this.state.page;

        if (newLastPage < currentPage){
            currentPage = newLastPage;
        }

        this.setState({
            page_size: pageSize,
            page: currentPage,
            formSubmissionsLoading: true
        });

        this.getFormSubmissions(this.state.selectedProject, this.state.sort_by, this.state.sort_type, this.state.dateFilter, this.state.descriptionFilter,
            this.state.modifiedFilter, this.state.statusFilter, currentPage, pageSize)
      }
    
    handlePageChange = (newPage: number) => {
        this.setState({
            page: newPage,
            formSubmissionsLoading: true
        });

        this.getFormSubmissions(this.state.selectedProject, this.state.sort_by, this.state.sort_type, this.state.dateFilter, this.state.descriptionFilter,
            this.state.modifiedFilter, this.state.statusFilter, newPage, this.state.page_size)
      }

    sortSubmittedDateColumn = () => {
        var newSort;
        const currentSort = this.state.sort_type;

        // if sort is currently ascending, change to descending.  if sort is currently decending, change to ascending.
        if (currentSort === "asc") {
            newSort = "desc";
        } else {
            newSort = "asc"
        }

        this.setState({
            sort_type: newSort,
            formSubmissionsLoading: true
        });

        this.getFormSubmissions(this.state.selectedProject, this.state.sort_by, newSort, this.state.dateFilter, this.state.descriptionFilter,
            this.state.modifiedFilter, this.state.statusFilter, this.state.page, this.state.page_size)
      }

    filterSubmittedDateColumn = (date: string) => {  
        this.setState({
            page: 1,
            dateFilter: date,
            formSubmissionsLoading: true
        });

        this.getFormSubmissions(this.state.selectedProject, this.state.sort_by, this.state.sort_type, date, this.state.descriptionFilter,
            this.state.modifiedFilter, this.state.statusFilter, 1, this.state.page_size)
      }
    
    filterDescriptionColumn = (event: any) => {
        const filter = event.target.value;
        
        this.setState({
            page: 1,
            descriptionFilter: filter,
            formSubmissionsLoading: true
        });

        this.getFormSubmissions(this.state.selectedProject, this.state.sort_by, this.state.sort_type, this.state.dateFilter, filter,
            this.state.modifiedFilter, this.state.statusFilter, 1, this.state.page_size)
      }

    filterLastModifiedByColumn = (event: any) => {
        const filter = event.target.value;

        this.setState({
            page: 1,
            modifiedFilter: filter,
            formSubmissionsLoading: true
        });

        this.getFormSubmissions(this.state.selectedProject, this.state.sort_by, this.state.sort_type, this.state.dateFilter, this.state.descriptionFilter,
            filter, this.state.statusFilter, 1, this.state.page_size)
      }

    filterFormStatusColumn = (event: any) => {
        const filter = event.target.value;

        this.setState({
            page: 1,
            statusFilter: filter,
            formSubmissionsLoading: true
        });

        this.getFormSubmissions(this.state.selectedProject, this.state.sort_by, this.state.sort_type, this.state.dateFilter, this.state.descriptionFilter,
            this.state.modifiedFilter, filter, 1, this.state.page_size)
      }

    handleResetFilters = () => {
        this.setState({
            page: 1,
            dateFilter: "",
            descriptionFilter: "",
            modifiedFilter: "",
            statusFilter: "",
            formSubmissionsLoading: true
        });

        this.getFormSubmissions(this.state.selectedProject, this.state.sort_by, this.state.sort_type, "", "", "", "", 1, this.state.page_size);
    }

    onFormStatusModal = (entryID: string) => {
        this.setState({
            openFormStatusModal: true,
            taskUsersLoading: true
        });

        this.getTaskUsers(entryID);
      };

    onCloseFormStatusModal = () => {
        this.setState({
            openFormStatusModal: false,
            taskUsers: []
        });
      };

    render() {    
        const {
            projectLoading,
            projectList,
            selectedProject,
            formSubmissionsLoading,
            formSubmissionList,
            sort_by,
            sort_type,
            dateFilter,
            descriptionFilter,
            modifiedFilter,
            statusFilter,
            page,
            page_size,
            openFormStatusModal,
            taskUsersLoading,
            taskUsers
        } = this.state;
         
        const FormSubmissionsItems = formSubmissionsLoading ?
            <tr>
                <td colSpan={100}>
                    <Delayed waitBeforeShow={750}>
                        <div className="DivPadded DivCenter"><Spinner animation="border" role="status" variant="primary" /></div>
                    </Delayed>
                </td>
            </tr> :
                formSubmissionList.formSubmissions.length > 0 ? 
                    formSubmissionList.formSubmissions.map((submission) =>
                        <tr key={submission.id}>
                            <td>{new Date(submission.submittedDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit'})}</td>
                            <td>
                                {submission.hasTasks ? 
                                    <Link to={{pathname: '/dashboardTaskDetails', search: `?entryID=${submission.id}&sort_by=${sort_by}&sort_type=${sort_type}` + 
                                        `&date=${dateFilter}&description=${descriptionFilter}&modified=${modifiedFilter}&status=${statusFilter}&page=${page}&page_size=${page_size}`} }>
                                            {submission.formDescription}
                                    </Link> 
                                    : submission.formDescription}
                            </td>
                            <td>{submission.lastModifiedUser}</td>
                            <td>{submission.formStatus !== "Complete" ? 
                                <button onClick={() => this.onFormStatusModal(submission.id)} className="ButtonLink">{submission.formStatus}</button> : 
                                submission.formStatus}
                            </td>
                        </tr>
                    )
                    : <tr>
                        <td colSpan={100}>
                            <b>No Form Submissions Found</b>
                        </td>
                    </tr>;

        return ( 
            <div className='w-75'>
                <LoadingWrapper showLoading={projectLoading} loadingMessage="Getting Form Submissions..." windSpinner windSpinnerScale={3}>
                    <Modal show={openFormStatusModal} onHide={this.onCloseFormStatusModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Task Assigned To:</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                {taskUsersLoading ? 
                                    <div className="DivPadded DivCenter"><Spinner animation="border" role="status" variant="primary" /></div> :
                                    <ul>
                                        {taskUsers.map((user, i) => 
                                            <li key={i}>{user}</li>
                                        )}
                                    </ul>}
                            </div>
                        </Modal.Body>
                    </Modal>

                    <ProjectDropdown projectList={projectList} cookieName='projects' selectedProjectHandler={this.handleProjectChange} />

                    <h1 className="header mt-5 mb-4 text-center">Form Submissions</h1>

                    {selectedProject ?  
                        <div>  
                            <div className="d-flex justify-content-start align-items-center">
                                <b><label htmlFor="pageSize">Page Size:</label></b>
                                <div className="DivPadded">
                                    <SelectField
                                        dataName="pageSize"
                                        title=""
                                        options={[{value: "50"}, {value: "100"}, {value: "250"}]}
                                        value={page_size.toString()}
                                        onchange={this.handlePageSizeChange} />      
                                </div>
                                {dateFilter || descriptionFilter || modifiedFilter || statusFilter ? 
                                    <ButtonType dataName="filterReset" buttonText="Reset Filters" eventHandler={() => this.handleResetFilters()} />
                                    : null}
                            </div>
                            <div className="d-flex flex-wrap align-items-center">
                                <div className="p2">
                                    <MyPagination currentPage={page} pages={Math.ceil(formSubmissionList.totalCount/page_size)} onPageChange={(newPage) => this.handlePageChange(newPage)} />
                                </div>
                                <div className="flex-grow-1 flex-wrap DivRight"><p><i>Note: This data is refreshed every two hours from 6 AM to 6 PM CST</i></p></div>     
                            </div>    
                            
                            <Table>
                                <thead>
                                    <tr>
                                        <th className="ColumnSort" onClick={this.sortSubmittedDateColumn} style={{width: 75}}>Submitted Date {sort_type === "" ? " ⮃" : sort_type === "asc" ? " ⭡" : " ⭣"}</th>
                                        <th style={{width: 275}}>Form Description</th>
                                        <th style={{width: 125}}>Last Modified By</th>
                                        <th style={{width: 125}}>Form Status</th>
                                    </tr>
                                    <tr key="filters">
                                        <td><DateField dataName="dateFilter" title="" value={dateFilter} onchange={this.filterSubmittedDateColumn} /></td>
                                        <td><TextField dataName="descriptionFiler" title="" value={this.state.descriptionFilter} onchange={this.filterDescriptionColumn} placeholder="filter description" /></td>
                                        <td><TextField dataName="modifiedFilter" title="" value={modifiedFilter} onchange={this.filterLastModifiedByColumn} placeholder="filter modified by" /></td>
                                        <td>
                                            <SelectField dataName="statusFilter" title="" allowSelectOption={true} options={[ { value: "In Progress" }, { value: "Complete" }]} 
                                                value={statusFilter} onchange={this.filterFormStatusColumn} />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {FormSubmissionsItems}
                                </tbody>
                            </Table>
                        </div>
                    : null}
                </LoadingWrapper>
            </div>
        );
    }
}