import { Component } from "react";
import { appInsights } from '../services/appInsights';
import { MsalContext } from '@azure/msal-react';
import { ListGroup } from 'react-bootstrap';
import TaskService from '../services/TaskService';
import ITaskComment from '../types/Task/TaskComment';
import ButtonType from './ButtonType';
import TextArea from './TextArea';
import LoadingWrapper from "./LoadingWrapper";

type Props = {
    taskID: number,
    preloadedComments: ITaskComment[],
    addEvent: (e: any) => void,
    readonly?: boolean
}

type State = {
    comments: ITaskComment[],
    newComment: string,
    pendingAdd: boolean
};

export default class TaskDiscussion extends Component<Props, State> {
    static contextType = MsalContext;

    constructor(props: Props) {
        super(props);
        this.state = {
            comments: this.props.preloadedComments,
            newComment: "",
            pendingAdd: false
        };
    }

    // This function is called when the input changes
    inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const enteredValue = event.target.value;
        this.setState({ newComment: enteredValue });
    };

    // This function is called when new comment is added
    handleAddComment = () => {
        appInsights.trackEvent({name: "Comment Save button clicked"});

        const userEmail = this.context.accounts[0].username;
        const userName = this.context.accounts[0].name;

        this.setState({
            pendingAdd: true
        });

        let comment: ITaskComment = {
            id: this.props.taskID,
            comment: this.state.newComment,
            user: userEmail,
            date: new Date().toISOString()
        };

        TaskService.addComment(comment)
            .then((response) => { 
                comment.user = userName;

                this.setState( prevState => ({
                    pendingAdd: false,
                    newComment: "",
                    comments: [comment, ...prevState.comments]
                }));

                this.props.addEvent(this.state.comments);
            }).catch((e) => {
                this.setState({
                    pendingAdd: false
                });
            });
    }

    componentDidMount() {
        appInsights.trackPageView({name: "Discussion page"});
    }

    render() {
        const {
            comments,
            newComment,
            pendingAdd
        } = this.state;

        return (
            <div>
                <TextArea key={comments.length} dataName="newComment" title="Comments" rows={3} value={newComment} onchange={this.inputHandler} readonly={this.props.readonly || pendingAdd} />
                <ButtonType dataName="addComment" buttonText="Save" type="button" eventHandler={this.handleAddComment} readOnly={newComment.length === 0 || pendingAdd} />
                <hr />
                <LoadingWrapper showLoading={pendingAdd}>
                    <ListGroup>
                        {comments.map((comment, i) => {
                            let commentDate = new Date(comment.date);                        
                            return (
                                <ListGroup.Item key={i}>
                                    <p className="mb-1 commentListItem">{comment.comment}</p>
                                    <div className="d-flex">
                                        <small>- {comment.user}</small>
                                    </div>
                                    <div className="d-flex">
                                        <small>{commentDate.toLocaleString('en-US', {
                                                    month: 'short',
                                                    day: 'numeric',
                                                    year: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    hour12: true,
                                                    timeZoneName: 'short'
                                                })}
                                        </small>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </LoadingWrapper>
            </div>
        );
    }
}