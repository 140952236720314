import React from 'react';
import { Spinner } from 'react-bootstrap';
import Delayed from './Delayed';
import WindTurbineSpinner from './WindTurbineSpinner';

type LoadingWrapperProps = {
    className: string,
    children: React.ReactNode,
    showLoading: boolean,
    loadingMessage: string,
    windSpinner: boolean,
    windSpinnerScale: number
} & typeof defaultProps;

const defaultProps = {
    className: 'pt-5 text-center',
    showLoading: false,
    loadingMessage: '',
    windSpinner: false,
    windSpinnerScale: 1,
}

const LoadingWrapper = ({className, children, showLoading, loadingMessage, windSpinner, windSpinnerScale}: LoadingWrapperProps) => (
    showLoading ? (
        <Delayed waitBeforeShow={500}>
            <div className={className}>
                {windSpinner ? <WindTurbineSpinner scale={windSpinnerScale} /> : <Spinner animation="border" role="status" variant="primary"/>}
                <p style={{"marginTop": 15}}>{loadingMessage}</p>
            </div>
        </Delayed>
    ) : (
        <>
            {children}
        </>
    )
)

LoadingWrapper.defaultProps = defaultProps;

export default LoadingWrapper;