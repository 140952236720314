import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import RuleEditorHelp from './RuleEditorHelp';

import DataSourceDropDown from '../../components/DataSource/DropDown';
import DataSourceMessage from '../../components/DataSource/DataSourceMessage';
import WhereUsedRulesDisplay from '../../components/DataSource/WhereUsedRulesDisplay';
import RuleScopeDisplay from '../../components/DataSource/RuleScopeDisplay';
import DeleteButton from '../../components/DataSource/DeleteButton';
import SaveButton from '../../components/DataSource/SaveButton';
import InfoCircle from '../../components/Icons/InfoCircle';
import NewTab from '../../components/Icons/NewTab';
import ProjectDropDown from '../../components/ProjectDropdown';

import LoadingWrapper from '../../components/LoadingWrapper';
import { errorToast, successToast } from '../../components/Toasty';

import { QuantaMasterJobCodeList } from '../../constants/QuantaMasterJobCodeList';

import { BlattnerPortalPage } from '../../enums/BlattnerPortalPage';
import { DataSourceHistoryType } from '../../enums/DataSourceHistoryType';
import { DeleteStatus } from '../../enums/DeleteStatus';
import { SaveStatus } from '../../enums/SaveStatus';

import DataSourceService from '../../services/DataSourceService';
import DataService from '../../services/DataService';

import ProjectList from '../../types/Dashboard/ProjectList';
import IDataSourceResponse from '../../types/Data/DataSourcesResponse'
import Environment from '../../types/Data/Environment';
import CellValidationDisplay from '../../components/DataSource/CellValidationDisplay';
import { CellValidation, DataSourceRules, Permissions } from '../../types/DataSource/DataSourceRules';
import DataSourceRulesDetail from '../../types/DataSource/DataSourceRulesDetail';
import PermissionDisplay from '../../components/DataSource/PermissionsDisplay';
import DataSourceEmployeeInfo from '../../types/Employee/DataSourceEmployeeInfo';
import ListItem from '../../types/ListItem';

/**
 * It would be nice to pin down an outside (of the code) resource
 *   to contain this list so we can fetch it an not have to worry about
 *   being the maintainers of this "master data"
 * @returns array of ListItems
 */
const generateJobCodeMasterList = (): ListItem[] => {
    const completeJobCodes: ListItem[] = [];
    for (const key in QuantaMasterJobCodeList) {
        completeJobCodes.push({
            value: key,
            label: `${key} - ${QuantaMasterJobCodeList[key as keyof typeof QuantaMasterJobCodeList]}`,
        })
    }
    return completeJobCodes;
}

/**
 * setup a generic empty data source rule set
 * @returns an empty DataSourceRules
 */
const generateEmptyRules = (): DataSourceRules => {
    const emptyPermissions: Permissions = {
        users: [],
        jobCodes: [],
        adGroups: [],
        projects: [],
    }

    const canEditRules: Permissions = {
        ...emptyPermissions,
        // Lead App Dev, App Dev, Assoc App Dev, Sen App Dev, RE, Assoc RE
        jobCodes: ['BIT452', 'BIT354', 'BIT306', 'BIT406', 'BIT357', 'BIT307'],
    }

    const cellValidations = [{ column: 0, regex: '^\\d+$' }];

    const emptyRules: DataSourceRules = {
        canEditRules,
        cellValidations,
        editability: emptyPermissions,
        visibility: emptyPermissions
    }

    return emptyRules;
}

/**
 * This is a way for us to govern the behavior of the "Save" Button
 *   the purpose is to reduce any confusion that may arrise during
 *   editing as a feedback to the user on whether or not the values
 *   on screen differ from the dB.
 * NOTE: some of the checks are not _really_ needed as of yet, but in
 *   short order I would like to offer the ability to update the entirety
 *   of the rules to users and want to make sure all the bases are
 *   covered now
 * @param dsRules1 the rules that are updated via the user input
 * @param dsRules2 the rules that are updated via the user input
 * @returns true/false based on if the new rules have deviated from
 *   what is in the dB
 */
const areRulesEqual = (dsRules1: DataSourceRules | null | undefined, dsRules2: DataSourceRules | null | undefined): boolean => {
    // if either are null
    if (dsRules1 === null || dsRules1 === undefined|| dsRules2 === null || dsRules2 === undefined) { return false; }

    // if they are pointing to the same data
    if (dsRules1 === dsRules2) { return true; }

    // if any of the array lengths are mismatched
    if (
        (dsRules1.canEditRules.adGroups.length !== dsRules2.canEditRules.adGroups.length)
        || (dsRules1.canEditRules.jobCodes.length !== dsRules2.canEditRules.jobCodes.length)
        || (dsRules1.canEditRules.projects.length !== dsRules2.canEditRules.projects.length)
        || (dsRules1.canEditRules.users.length !== dsRules2.canEditRules.users.length)
        || (dsRules1.editability.adGroups.length !== dsRules2.editability.adGroups.length)
        || (dsRules1.editability.jobCodes.length !== dsRules2.editability.jobCodes.length)
        || (dsRules1.editability.projects.length !== dsRules2.editability.projects.length)
        || (dsRules1.editability.users.length !== dsRules2.editability.users.length)
        || (dsRules1.visibility.adGroups.length !== dsRules2.visibility.adGroups.length)
        || (dsRules1.visibility.jobCodes.length !== dsRules2.visibility.jobCodes.length)
        || (dsRules1.visibility.projects.length !== dsRules2.visibility.projects.length)
        || (dsRules1.visibility.users.length !== dsRules2.visibility.users.length)
        || (dsRules1.cellValidations?.length !== dsRules2.cellValidations?.length)
    ) {
        return false;
    }
    
    // we will assume that everything is sorted, because that is the way it
    //   it is handled throughout the process of data entry and retrieval

    // brute force check if the canEditRules has changed
    for(let i = 0; i < dsRules1.canEditRules.adGroups.length; ++i) {
        if (dsRules1.canEditRules.adGroups[i] !== dsRules2?.canEditRules.adGroups[i]) { return false; }
    }
    for(let i = 0; i < dsRules1.canEditRules.jobCodes.length; ++i) {
        if (dsRules1.canEditRules.jobCodes[i] !== dsRules2?.canEditRules.jobCodes[i]) { return false; }
    }
    for(let i = 0; i < dsRules1.canEditRules.projects.length; ++i) {
        if (dsRules1.canEditRules.projects[i] !== dsRules2?.canEditRules.projects[i]) { return false; }
    }
    for(let i = 0; i < dsRules1.canEditRules.users.length; ++i) {
        if (dsRules1.canEditRules.users[i] !== dsRules2?.canEditRules.users[i]) { return false; }
    }

    for(let i = 0; i < dsRules1.editability.adGroups.length; ++i) {
        if (dsRules1.editability.adGroups[i] !== dsRules2?.editability.adGroups[i]) { return false; }
    }
    for(let i = 0; i < dsRules1.editability.jobCodes.length; ++i) {
        if (dsRules1.editability.jobCodes[i] !== dsRules2?.editability.jobCodes[i]) { return false; }
    }
    for(let i = 0; i < dsRules1.editability.projects.length; ++i) {
        if (dsRules1.editability.projects[i] !== dsRules2?.editability.projects[i]) { return false; }
    }
    for(let i = 0; i < dsRules1.editability.users.length; ++i) {
        if (dsRules1.editability.users[i] !== dsRules2?.editability.users[i]) { return false; }
    }

    for(let i = 0; i < dsRules1.visibility.adGroups.length; ++i) {
        if (dsRules1.visibility.adGroups[i] !== dsRules2?.visibility.adGroups[i]) { return false; }
    }
    for(let i = 0; i < dsRules1.visibility.jobCodes.length; ++i) {
        if (dsRules1.visibility.jobCodes[i] !== dsRules2?.visibility.jobCodes[i]) { return false; }
    }
    for(let i = 0; i < dsRules1.visibility.projects.length; ++i) {
        if (dsRules1.visibility.projects[i] !== dsRules2?.visibility.projects[i]) { return false; }
    }
    for(let i = 0; i < dsRules1.visibility.users.length; ++i) {
        if (dsRules1.visibility.users[i] !== dsRules2?.visibility.users[i]) { return false; }
    }

    if (dsRules1.cellValidations !== undefined && dsRules2.cellValidations !== undefined) {
        for(let i = 0; i < dsRules1.cellValidations?.length; ++i) {
            if ((dsRules1.cellValidations[i].column !== dsRules2.cellValidations[i].column)
              && (dsRules1.cellValidations[i].regex !== dsRules2.cellValidations[i].regex))
            {
                return false;
            }
        }
    }

    // finally they must be equal if everthing else is ruled out
    return true;
};


type RuleEditorComponentProps = {
    userData: DataSourceEmployeeInfo;
    projectList: ProjectList;
    masterEmployeeList: ListItem[];
};

const RuleEditorComponent = ({ userData, projectList, masterEmployeeList }: RuleEditorComponentProps) => {
    /**
     * States for handling project and data source selections
     */
    // main project dropdown
    const [selectedProject, setSelectedProject] = useState<string>('');
    const [updatedProject, setUpdatedProject] = useState<string>('');
    // needed for handling adding of the Dev V03 and Dev Test for environment selection
    const [ruleProjectList, setRuleProjectList] = useState<ProjectList>();
    // selected data source info
    const [dataSourceHeaders, setHeaders] = useState<IDataSourceResponse>();
    const [selectedDataSource, setSelectedDataSource] = useState<string>('');
    const [selectedDataSoureceName, setSelectedDataSourceName] = useState<string>('');

    /**
     * States for managing rules
     */
    // overarching rules details
    const [currentProjectSpecificValue, setCurrentProjectSpecificValue] = useState<boolean>();
    const [rulesDetails, setRulesDetails] = useState<DataSourceRulesDetail>();
    // specific rules to be manipulated
    const [dataSourceRules, setDataSourceRules] = useState<DataSourceRules>(generateEmptyRules());
    // detecting rule change
    const [initialDataSourceRules, setInitialDataSourceRules] = useState<DataSourceRules>();
    // allowing for deletion of rules from the where used rules list
    const [targetDeleteEnvironment, setTargetDeleteEnvironment] = useState<string>('')

    /**
     * States for managing the where used list
     */
    const [whereUsedList, setWhereUsedList] = useState<Environment[]>([]);
    const [refreshWhereUsedList, setRefreshWhereUsedList] = useState<boolean>(false);

    /**
     * States for interoperation status
     */
    const [headersLoading, setHeadersLoading] = useState<boolean>(false);
    const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.Saved);
    const [deleteStatus, setDeleteStatus] = useState<DeleteStatus>(DeleteStatus.Undeleted);
    const [rulesLoading, setRulesLoading] = useState<boolean>(false);
    const [showRuleHelperModal, setShowRuleHelperModal] = useState<boolean>(false);
    const [editable, setEditable] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    /**
     * allows for forced refreshing of specific components
     */
    const [ruleKey, setRuleKey] = useState<string>(`Rules-${Date.now()}`);
    const [projectKey, setProjectKey] = useState<string>(`selectedProject${Date.now()}`)

    /**
     * this is used to catch when the project list changes and append the two
     *   projects to the list that we want available for all the data edits
     */
    useEffect(() => {
        const list = structuredClone(projectList);

        /**
         * These are used to catch the case in production where the list of projects
         *   that is presented to the "normal user" has Dev Test and Dev V.03
         *   removed.  But here we need to allow the REs and Devs access to both of
         *   those projects to effectively test and add DS Rules
         */
        // Dev V.03
        if (!list.projects.find((x) => x.projectID === '56824')) {
            list.projects.unshift({ projectID: '56824', projectNumber: '003', projectName: 'Dev V.03' });
        }
        // Dev Test
        if (!list.projects.find((x) => x.projectID === '68457')) {
            list.projects.unshift({ projectID: '68457', projectNumber: '978', projectName: 'Dev Test' });
        }

        setRuleProjectList(list);
    }, [projectList]) // dependency list left blank on purpose

    /**
     * this will catch the user and the ds rules then calculate if they can
     *   edit rules or just view
     */
    useEffect(() => {
        if(!dataSourceRules || !userData) {
            return;
        }
        /**
         * @returns true if the user can edit the rules else false
         */
        const checkCanEditRules = () => {
            const userADGroups = userData.adGroups.map(({ id }) => id);
            const adGroupsCheck = userADGroups.length === 0 ? 'false' : userADGroups.reduce((acc, next) => {
                    if (acc === 'true') {
                        return acc;
                    }
                    if (dataSourceRules.canEditRules.adGroups.includes(acc)) {
                        return 'true';
                    }
                    return next;
                });
            const userProjectsCheck = userData.projects.length === 0 ? 'false' : userData.projects.reduce((acc, next) => {
                    if (acc === 'true') {
                        return acc;
                    }
                    if (dataSourceRules.canEditRules.projects.includes(acc)) {
                        return 'true';
                    }
                    return next;
                });
            return (
                dataSourceRules.canEditRules.jobCodes.some((x) => x === userData.jobCode)
                || dataSourceRules.canEditRules.users.some((x) => x === userData.email)
                || adGroupsCheck === 'true'
                || userProjectsCheck === 'true'
            );
        }


        setEditable(checkCanEditRules())
    }, [userData, dataSourceRules, setEditable])

    /**
     * Allows us to easily get the project name string from the environment ID
     * @param environmentID // the target environment for which to get the name
     * @returns string including the "project number - project name" or "No projcet found" if not available
     */
    const getProjectNameFromID = (environmentID: string): string => {
        if (!ruleProjectList || !ruleProjectList.projects) {
            return 'No project found';
        }

        const { projectName, projectNumber } = ruleProjectList
            ?.projects
            ?.find((project) => (project.projectID === environmentID))
            ?? { projectName: '', projectNumber: ''};
        return (`${projectNumber} - ${projectName}`);
    }

    /**
     * used to set the current Data Source name
     * @param value Data source name
     */
    const handleDataSourceChange = async (value: string) => {
        setSelectedDataSource(value);
        let name = '';
        if (dataSourceHeaders && dataSourceHeaders.dataSources) {
            name = dataSourceHeaders.dataSources.find((header) => (header.externalId === value))?.name ?? '';
        }
        setSelectedDataSourceName(name);
    };

    /**
     * manage the list of "Where Used" projects
     */
    useEffect(() => {
        // we always want to set the status to undeleted when we switch between rules
        setDeleteStatus(DeleteStatus.Undeleted);
        if (!selectedProject || !selectedDataSource || !ruleProjectList) {
            setWhereUsedList([]);
            return;
        }

        const updateWhereUsedList = async () => {
            try {
                const { data }  = await DataSourceService.getAllDataSourceRules(selectedDataSource);
                const rulesList = data
                    .filter((rule) => (rule.environmentID !== selectedProject))
                    .map((rule) =>  {
                        const project = ruleProjectList.projects.find((project) => (project.projectID === rule.environmentID));
                        return ({
                            id: rule.environmentID,
                            name: `${project?.projectNumber} - ${project?.projectName}`,
                        });
                    });
                setWhereUsedList(rulesList);
            } catch (e: any) {
                console.log('errors:', e);
            }
        }

        updateWhereUsedList();
    }, [selectedProject, selectedDataSource, refreshWhereUsedList]);

    /**
     * this handles when a project is selected from the dropdown by a user
     *   This is because the selected project can be manipulated on different
     *   pages of the application
     */
    useEffect(() => {
        if (headersLoading || (!userData.email)) {
            return;
        }

        const getDataSources = async (companyID?: string) => {
            if (!companyID) {
                return;
            }
            setHeadersLoading(true);
            try {
                const [rulesResponse, headerResponse] = await Promise.all([
                    DataSourceService.getAllDataSourceRules(),
                    DataService.getAllHeaders(selectedProject)
                ]);

                const headers = headerResponse.data;

                /**
                 * At some point it would be nice if the data source
                 *   names still showed up, but were un-selectable.  This is
                 *   out of scope for now, but should be added as a future
                 *   story (Story 93380)
                 */

                // If we are NOT using the primary environment we want to remove any data
                //   sources that are globaly scoped
                // currently the primary environment is Dev V.03 56824
                if (companyID !== process.env.REACT_APP_PRIMARY_FOF_ENVIRONMENT) {
                    // find the globaly scoped data sources
                    const rules = rulesResponse.data.filter(x => (
                        x.environmentID === process.env.REACT_APP_PRIMARY_FOF_ENVIRONMENT
                        && x.projectSpecific === false
                    ));

                    // filter them out of the data source list.  We need to
                    //  account for when there are no global rules to remove
                    headers.dataSources = rules.length === 0 ?  headers.dataSources : headers.dataSources.filter(datasource => (
                        !rules.some(rule => (rule.externalID === datasource.externalId))
                    ));
                }

                // if the newly selected project doesn't allow editing for the rules (i.e. global data sources not in Dev V.03)
                //   then we clear the selected datasource
                if (!headers.dataSources.some((dataSource) => dataSource.externalId === selectedDataSource)) {
                    setSelectedDataSource('');
                    setSelectedDataSourceName('');
                }
                setHeaders(headers);
                setHeadersLoading(false);

            } catch (error) {
                console.log('There was an error getting the DS info:', error)
                setSelectedDataSource('');
                setSelectedDataSourceName('');
            }
        }

        getDataSources(selectedProject);
        // exclude headersLoading, userData.email, and project to prevent reloading
        //   as page loads in other components
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);

    /**
     * this will grab the new data for the given project and data source when
     *   the selection changes
     */
    useEffect(() => {
        if (!selectedProject || !selectedDataSource) {
            return;
        }
        setRulesLoading(true);
        // get the data source rules details
        DataSourceService.getDataSourceRules(selectedProject, selectedDataSource, false)
            .then((response) => {
                let details: DataSourceRulesDetail = response.data;

                // if the segment is undefined we know the rule is not setup
                //   so we can set up a generic rule Detail here
                if (!details.segment && !details.rules) {
                    details.segment = 'both';
                    details.projectSpecific = true;
                    details.rules = JSON.stringify(generateEmptyRules());
                }
                setRulesDetails(details);

                let rules = JSON.parse(details.rules)

                setCurrentProjectSpecificValue(details.projectSpecific);
                setDataSourceRules(structuredClone(rules));
                setInitialDataSourceRules(structuredClone(rules));
            })
            .catch((e) => {
                console.log('error: ', e)
            })
            .finally (() => {
                setRulesLoading(false);
            });
    /**
     * selectedProject is not included in deps on purpose
     *   we only want this to run when the selected Data Source has changed
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject, selectedDataSource])

    
    /**
     * This is used to return a selector with the avaialbe Data Sources for viewing
     * @param headers the list of data sources for the company/environment
     * @returns A drop down list of available Data Sources for the user
     */
    const selectDataSourceHeaders = (headers: IDataSourceResponse) => {
        if (!headers) {
            return;
        }
        return (
            <DataSourceDropDown
                dataSourceList={headers.dataSources}
                selectionHandler={handleDataSourceChange}
                cookiesName='rulesEditor'
            />
        );
    };

    /**
     * handle update to the visibility permission set
     *   sets data source rules and save status
     * @param visibility
     * @returns nothing
     */
    const handleVisibilityPermissionsChange = (visibility: Permissions) => {
        if (!dataSourceRules) {
            return;
        }
        const newRules: DataSourceRules = structuredClone(dataSourceRules);
        newRules.visibility = visibility;
        setDataSourceRules(newRules);


        if (areRulesEqual(structuredClone(newRules), initialDataSourceRules)) {
            setSaveStatus(SaveStatus.Saved);
            setDeleteStatus(DeleteStatus.Deleted);
        } else {
            setSaveStatus(SaveStatus.Unsaved);
            setDeleteStatus(DeleteStatus.Undeleted);
        }
    }

    /**
     * handle update to the editability permission set
     *   sets data source rules and save status
     * @param editability
     * @returns nothing
     */
    const handleEditabilityPermissionsChange = (editability: Permissions) => {
        if (!dataSourceRules) {
            return;
        }
        const newRules: DataSourceRules = structuredClone(dataSourceRules);
        newRules.editability = editability;
        setDataSourceRules(newRules);

        if (areRulesEqual(structuredClone(newRules), initialDataSourceRules)) {
            setSaveStatus(SaveStatus.Saved);
            setDeleteStatus(DeleteStatus.Deleted);
        } else {
            setSaveStatus(SaveStatus.Unsaved);
            setDeleteStatus(DeleteStatus.Undeleted);
        }
    }

    /**
     * handle update to the cell validations
     *   sets data source rules and save status
     * @param validations
     * @returns nothing
     */
    const handleCellValidationChange = (validations: CellValidation[]) => {
        if (!dataSourceRules) {
            return;
        }

        const newRules: DataSourceRules = structuredClone(dataSourceRules);
        newRules.cellValidations = validations;
        setDataSourceRules(newRules);

        if (areRulesEqual(structuredClone(newRules), initialDataSourceRules)) {
            setSaveStatus(SaveStatus.Saved);
            setDeleteStatus(DeleteStatus.Deleted);
        } else {
            setSaveStatus(SaveStatus.Unsaved);
            setDeleteStatus(DeleteStatus.Undeleted);
        }
    };

    /**
     * add the button click to the history table in azure db
     * @param status 
     * @returns nothing
     */
    const recordSaveClick = (status: SaveStatus) => {
        if (!selectedProject || !selectedDataSource) {
            return;
        }
        let claimStatusText = '';
        switch (status) {
            case SaveStatus.Saved: claimStatusText = 'Success'; break;
            case SaveStatus.Error: claimStatusText = 'Error'; break;
        }
        // not awaiting on purpose: as there is no need to wait for the result
        DataSourceService.addDataSourceHistory(selectedProject, selectedDataSource, {
            date: Date.now(),
            eventType: DataSourceHistoryType.Save,
            page: BlattnerPortalPage.RulesEditor,
            message: `result: ${claimStatusText}`,
            employeeID: userData.employeeID.toString(),
        })
        .catch((e) => {
            console.log("Error happened recording save click in rules editor", e)
        });
    };

    /**
     * This is used to do the work of actually saving the updated rules to the database
     */
    const saveRules = async () => {
        if (!rulesDetails) {
            return;
        }

        const newRulesDetail = structuredClone(rulesDetails);
        if (!newRulesDetail) {
            return;
        }

        // capture any new values that may be associated with this rule set
        // if the currentProjectSpecificValue is undefined default to true
        // this is the safest behavior
        newRulesDetail.projectSpecific = currentProjectSpecificValue === undefined ? true : currentProjectSpecificValue;
        newRulesDetail.rules = JSON.stringify(dataSourceRules);

        // save them to the state
        setRulesDetails(newRulesDetail);

        try {
            await DataSourceService.setDataSourceRules(selectedProject, selectedDataSource, newRulesDetail);
            const successMsg = (
                <div>
                    <p className='text-center' >
                        {selectedDataSoureceName} Rule
                        Saved Successfully to {getProjectNameFromID(selectedProject)}
                        Nice Work!!!!
                    </p>
                </div>
            );

            setInitialDataSourceRules(structuredClone(dataSourceRules));
            successToast(DataSourceMessage(selectedDataSoureceName, successMsg));
            setSaveStatus(SaveStatus.Saved);
            recordSaveClick(SaveStatus.Saved);
        } catch (error: any) {
            setSaveStatus(SaveStatus.Error);
            recordSaveClick(SaveStatus.Error);
            const errorMsg = (
                <div>
                    <p className='text-center' >
                        There was an issue saving the rules.
                    </p>
                    <p>
                        {error}
                    </p>
                </div>
            );
            errorToast(DataSourceMessage(selectedDataSoureceName, errorMsg));
            throw new Error('Error while saving Data Source Rules', error);
        };
    };

    /**
     * catch the Save Click on the DS Rules
     *   set save status
     */
    const handleSaveClick = () => {
        if (saveStatus === SaveStatus.Unsaved) {
            setSaveStatus(SaveStatus.Saving);
            saveRules();
        }
    };

    /**
     * add the delete button click to the history table in azure db
     * @param status
     * @returns nothing
     */
    const recordDeleteClick = (status: DeleteStatus) => {
        if (!targetDeleteEnvironment || !selectedDataSource) {
            return;
        }
        let statusText = '';
        switch (status) {
            case DeleteStatus.Deleted: statusText = 'Success'; break;
            case DeleteStatus.Error: statusText = 'Error'; break;
        }
        // not awaiting on purpose: as there is no need to wait for the result
        DataSourceService.addDataSourceHistory(targetDeleteEnvironment, selectedDataSource, {
            date: Date.now(),
            eventType: DataSourceHistoryType.Delete,
            page: BlattnerPortalPage.RulesEditor,
            message: `result: ${statusText}`,
            employeeID: userData.employeeID.toString(),
        })
        .catch((e) => {
            console.log("Error happened recording delete click in rules editor", e)
        });
    };

    /**
     * Delete the given environment
     * @param environmentID target environment for deleting the selectedDataSource
     */
    const deleteRule = async (environmentID: string) => {
        /**
         * this checks if it is the current rule in focus (showing on the screen)
         * we can skip a bunch of stuff if it is a project from the "where used list"
         */
        const updateFocusedRule: boolean = (environmentID === selectedProject);

        try {

            if (updateFocusedRule) {
                setDeleteStatus(DeleteStatus.Deleting);
            }
            await DataSourceService.deleteDataSourceRule(environmentID, selectedDataSource, userData.email)
            const successMsg = (
                <div>
                    <p className='text-center' >
                        {selectedDataSoureceName} Rule
                        Deleted Successfully from {getProjectNameFromID(environmentID)}
                        Nice Work!!!!
                    </p>
                </div>
            );

            /**
             * this checks if it is the current rule in focus (showing on the screen)
             * we can skip this if it is a project from the "where used list"
             */
            if (updateFocusedRule) {
                setInitialDataSourceRules(structuredClone(generateEmptyRules()));
                setDataSourceRules(generateEmptyRules())
                setDeleteStatus(DeleteStatus.Deleted);
            }

            successToast(DataSourceMessage(selectedDataSoureceName, successMsg));
            recordDeleteClick(DeleteStatus.Deleted);
        } catch (error: any) {
            if (updateFocusedRule) {
                setDeleteStatus(DeleteStatus.Error);
            }
            recordDeleteClick(DeleteStatus.Error);
            const errorMsg = (
                <div>
                    <p className='text-center' >
                        There was an issue deleting the rules.
                    </p>
                    <p>
                        {error}
                    </p>
                </div>
            );
            errorToast(DataSourceMessage(selectedDataSoureceName, errorMsg));
            throw new Error('Error while saving Data Source Rules', error);
        } finally {
            setShowDeleteModal(false);
            // if we are deleting the rules being displayed then we can skip updating the list
            if (!updateFocusedRule) {
                setRefreshWhereUsedList(!refreshWhereUsedList);
            }
            setRuleKey(`Rules-${Date.now()}`)
        };
    }

    /**
     * Allows for handling the deletion of a rule from either the displayed
     *   rule or one from the where used list
     * @param environmentID environmentID to delete
     */
    const handleDeleteClick = (environmentID: string) => {
        if (deleteStatus === DeleteStatus.Undeleted || environmentID !== selectedDataSource) {
            setTargetDeleteEnvironment(environmentID);
            setShowDeleteModal(true)
        }
    }

    /**
     * catch the update to the 'project specific' rule
     *   set current projectect specific value and save status
     * @param value
     * @returns nothing
     */
    const handleRuleScopeChange = ((value: boolean) => {
        if (!rulesDetails) {
            return;
        }

        setCurrentProjectSpecificValue(value);

        if (value !== rulesDetails.projectSpecific) {
            setSaveStatus(SaveStatus.Unsaved);
        } else {
            setSaveStatus(SaveStatus.Saved)
        }
    });

    /**
     * Manage switching to a new project by clicking on a project in the
     *   where used list
     * @param environmentID the project we are going to switch to
     */
    const onProjectClick = (environmentID: string) => {
        setSelectedProject(environmentID);
        setUpdatedProject(environmentID);

        // these are needed to force a re-render of the given components
        setProjectKey(`selectedProject${Date.now()}`)
        setRuleKey(`Rules-${Date.now()}`)
    };

    return (
        <div className='data-source-page'>

            <Modal
                className='modal-xl'
                show={showRuleHelperModal}
                onHide={() => setShowRuleHelperModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='data-source-help-modal'>
                        <h3>
                            Rules Editor Help&nbsp;
                            <a
                                href='ruleEditor/ruleEditorHelp'
                                target='_blank'
                                onClick={() => setShowRuleHelperModal(false)}
                                title='open in new tab'
                            >
                                <NewTab />
                            </a>
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {RuleEditorHelp()}
                </Modal.Body>
            </Modal>
            <Modal
                className='modal'
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='data-source-help-modal'>
                            <div className=' d-flex justify-content-center'>
                                {`Delete Rule from '${getProjectNameFromID(targetDeleteEnvironment)}'`}
                            </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <DeleteButton
                            deleteStatus={deleteStatus}
                            onClick={() => {
                                deleteRule(targetDeleteEnvironment)
                            }}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <div className='row border-bottom mx-3'>
                <div
                    className='col-1 mt-4 pt-1 text-center data-source-help-btn'
                    onClick={() => setShowRuleHelperModal(true)}
                >
                    <p className='text-center'>
                        Rule Help <br/>
                        <InfoCircle size="20" />
                    </p>
                </div>
                <div className='col-1 mt-4 pt-1 d-flex justify-content-center align-items-center'>
                </div>
                <div className='col-3'>
                    {
                        ruleProjectList &&
                        <ProjectDropDown
                            key={projectKey}
                            projectList={ruleProjectList}
                            selectedProjectHandler={setSelectedProject}
                            cookieName='rulesProjects'
                            updatedProject={updatedProject}
                        />
                    }
                </div>
                <div className='col-3'>
                    <LoadingWrapper showLoading={headersLoading}>
                        {dataSourceHeaders && selectDataSourceHeaders(dataSourceHeaders)}
                    </LoadingWrapper>
                </div>
                <div className='col-1 mt-4 pt-1 d-flex justify-content-center align-items-center'>
                    {
                        editable &&
                        <SaveButton
                            saveStatus={saveStatus}
                            onClick={() => handleSaveClick()}
                        />
                    }
                </div>
                <div className='col-1 mt-4 pt-1 d-flex justify-content-center align-items-center'>
                    {
                        editable &&
                        !areRulesEqual(generateEmptyRules(), initialDataSourceRules) &&
                        <DeleteButton
                            deleteStatus={deleteStatus}
                            onClick={() => handleDeleteClick(selectedProject)}
                        />
                    }
                </div>
            </div>
            <div className='row pt-1 mt-1'>
                <div
                    className='col'
                    key={`${ruleKey}`}
                >
                    {
                        selectedDataSource &&
                        <LoadingWrapper showLoading={rulesLoading}>
                            {
                                dataSourceRules && (masterEmployeeList) &&
                                <div>
                                    <div className='mt-3'>
                                        <RuleScopeDisplay
                                            value={rulesDetails?.projectSpecific ? true : false}
                                            editable={editable}
                                            onChange={(value: boolean) => handleRuleScopeChange(value)}
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <PermissionDisplay
                                            title={'Visibility'}
                                            permissions={structuredClone(dataSourceRules.visibility)}
                                            editable={true}
                                            masterJobCodeList={generateJobCodeMasterList()}
                                            masterEmployeeList={masterEmployeeList}
                                            onChange={(value) => handleVisibilityPermissionsChange(value)}
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <PermissionDisplay
                                            title={'Editability'}
                                            permissions={structuredClone(dataSourceRules.editability)}
                                            editable={true}
                                            masterJobCodeList={generateJobCodeMasterList()}
                                            masterEmployeeList={masterEmployeeList}
                                            onChange={(value) => handleEditabilityPermissionsChange(value)}
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <CellValidationDisplay
                                            title={'Column Cell Validations'}
                                            validations={structuredClone(dataSourceRules.cellValidations)}
                                            editable={true}
                                            onChange={handleCellValidationChange}
                                        />
                                    </div>
                                </div>
                            }
                        </LoadingWrapper>
                    }
                </div>
                <div className='col'>
                </div>
                <div className='col'>
                    {
                        selectedDataSource &&
                        <div className='mt-3'>
                            <WhereUsedRulesDisplay
                                whereUsedList={whereUsedList}
                                onProjectClick={onProjectClick}
                                onDeleteClick={handleDeleteClick}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default RuleEditorComponent;