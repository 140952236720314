import http from "../http-common";
import EnvironmentData from "../types/FormsOnFire/EnvironmentData";
import { EnvironmentQuery } from "../types/FormsOnFire/EnvironmentQuery";

/**
 * Returns the query string to be used in the GET for retrieving Environment Data
 * @param query
 * @returns query string for getting environment data
 */
export const BuildGetEnvironmentQueryString = (query: EnvironmentQuery) => {
    // if get all is set we don't need any of the other query information so just return 'getAll'
    if (query.getAll !== undefined) {
        return `&GetAll=${query.getAll}`;
    }
    // otherwise build out the string from the query data array
    return query.metaDataQueryList?.reduce((finalString, current, index) => {
        return finalString
            + `&MetaDataQueryList[${index}].Key=${current.key}`
            + `&MetaDataQueryList[${index}].Regex=${current.regex}`;
    }, '');
}

class FormsOnFireService {
    getEnvironment(query: EnvironmentQuery, active: boolean = true) {
        return http.get<EnvironmentData[]>(`/getEnvironments?active=${active}${BuildGetEnvironmentQueryString(query)}`);
    }
}

const formsOnFireServiceInstance = new FormsOnFireService();

export default formsOnFireServiceInstance;