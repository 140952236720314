import { MsalContext } from "@azure/msal-react";
import React from'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DataSourceDropDown from '../components/DataSource/DropDown';
import { errorToast, successToast } from "../components/Toasty";
import CircleX from '../components/Icons/CircleX';
import CircleCheck from '../components/Icons/CircleCheck';
import LoadingWrapper from "../components/LoadingWrapper";
import SelectField from "../components/SelectField";
import { GroupList } from '../enums/GroupList';
import { checkIfOneOffNCR, hasRuleEditorAccess, reliabilityEngineerAccess, developerAccess } from '../functions/AdminPageAccess';
import JobCodeList from "../functions/JobCode";
import { appInsights } from '../services/appInsights';
import DataService from "../services/DataService";
import TaskService from '../services/TaskService';
import TestService from '../services/TestService';
import DataSourceResponse from '../types/Data/DataSourcesResponse'
import DataSourceEmployeeInfo from "../types/Employee/DataSourceEmployeeInfo";
import ITestGroup from '../types/TestGroup';
import TextField from "../components/TextField";
import ButtonType from "../components/ButtonType";
import FormEntryService from "../services/FormEntryService";

type Props = {
    preloadedGroups?: ITestGroup[];
    updateTestGroups?: (e: any) => void;
    currentTestJobCode?: string;
    updateTestJobCode?: (jobCode: string) => void;
    updateUseTestUser?: () => void;
    currentJobCode?: string;
    usingTestUser?: boolean;
    userData?: DataSourceEmployeeInfo;
    projectSelector?: React.ReactNode;
    selectedProject?: string;
};

type Group = {
    value: string;
    label: string;
}

type ncrSubmission = {
    value: string;
    success: boolean;
    running: boolean;
}

type State = {
    selectedOptionsGroup: Group[];
    selectOptionsGroup: Group[];
    defaultGroupValues: ITestGroup[];
    ncrSubmissionList: ncrSubmission[];
    selectedJobCode: string;
    headersLoading: boolean;
    dataSourceHeaders: DataSourceResponse | undefined;
    selectedDataSource: string;
    resettingCache: boolean;
    resettingCacheError: boolean;
    missingNCR: {
        companyID: string,
        formEntryID: string
    };
    missingNCRStatus: string;
}

const animatedComponents = makeAnimated();

class AdminPage extends React.Component<Props, State> {
    static contextType = MsalContext;

    // eslint-disable-next-line no-useless-constructor
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedOptionsGroup : [],
            selectOptionsGroup: [],
            defaultGroupValues : this.props.preloadedGroups ?? [],
            ncrSubmissionList: [],
            selectedJobCode: this.props.currentJobCode ?? '',
            headersLoading: false,
            dataSourceHeaders: undefined,
            selectedDataSource: '',
            resettingCache: false,
            resettingCacheError: false,
            missingNCR: {
                companyID: "",
                formEntryID: ""
            },
            missingNCRStatus: ""
        };
    }

    setAllGroups() {
        const options = Object.entries(GroupList).map(value => ({
            "value" : value[0],
            "label" : value[1]
        }))

        this.setState({selectOptionsGroup: options})
    }

    //Getting all Groups
    getGroupValues() {
        const userEmail = this.context.accounts[0].username;
        TestService.getGroup(userEmail)
        .then((response) => {
            if (this.state.defaultGroupValues !== response.data) {
                this.setState({
                    defaultGroupValues: response.data
                });
                
                if (this.props.updateTestGroups) {
                    this.props.updateTestGroups(response.data);
                }
            }
        }).catch((e) => {
            console.log(e);
        });
    }

    /// Function to send data to our API.
    async sendGroupsToAPI(selectedOptionsGroup: Group[], username: string) {

        //creates a pipe separated group list and remove the last pipe.
        var groupString = "";
        selectedOptionsGroup.map(o => groupString = groupString + o.value + "|")
        groupString = groupString.slice(0, -1);

        // Create TestGroup object
        var data: ITestGroup = {
            id: 0,
            userEmail: username,
            group: groupString
        }

        TestService.insertGroup(data)
            .then((response) => {
                // Refresh group list
                this.getGroupValues();
            }).catch((e) => {
                console.log(e);
            });
    }

    getHeaders = async () => {
        this.setState({
            headersLoading: true,
        });
        try {
            if (!this.props.selectedProject) {
                throw new Error('User needs to select a project')
            }
            const response = await DataService.getAllHeaders(this.props.selectedProject)
            this.setState({
                dataSourceHeaders: response.data,
            })
        } catch (error) {
            console.log('Error occurred while getting the Data Source headers', error)
        } finally {
            this.setState({
                headersLoading: false,
            });
        }
    }

    componentDidMount() {
        this.setAllGroups();
        this.getHeaders();
        appInsights.trackPageView({name: "Admin Page"});
    }

    handleChangeSelectGroup = ( selectedOptionsGroup: Group[]) => {
        this.setState({ selectedOptionsGroup });
    }


    ncrList: string[] = [];

    processNCR = async (ncrString: string) => {
        if (!this.ncrList.includes(ncrString)) {
            console.log(`${ncrString} not found in NCR List`);
            return;
        }
        // get the list info right away
        const list = this.state.ncrSubmissionList;
        let submission = list?.find((n) => n.value === ncrString);
        // check to make sure it is non-reentrant
        // can leave out the success check if we want to be able to re-run the NCR
        if(submission?.success || submission?.running) {
            return;
        }

        // if the submission doesn't exist yet build it and push it.
        if(!submission) {
            submission = {
                value: ncrString,
                success: false,  // set to false after testing
                running: false
            }
            list.push(submission);
        }

        // get the index to make accessing the NCR Submission values easier
        let index = list.findIndex((n) => n.value === ncrString);

        list[index].running = true;
        this.setState({ncrSubmissionList: list});

        // -----  UNCOMMENT BELOW FOR INTEGRATION TESTING -----
        await TaskService.updateBmodsNcrById(ncrString)
        .then((response) => {
            if(response.data.includes('PASS')) {
              list[index].success = true;
            }
        })
        .catch((response) => {
            console.log(response);
        });

        list[index].running = false;
        this.setState({ncrSubmissionList: list});
    }

    updateMissingNCRCompanyID = (event: any) => {
        this.setState({
            missingNCR: {
                companyID: event.target.value,
                formEntryID: this.state.missingNCR.formEntryID
            }
        });
    }

    updateMissingNCRFormEntryID = (event: any) => {
        this.setState({
            missingNCR: {
                companyID: this.state.missingNCR.companyID,
                formEntryID: event.target.value
            }
        });
    }

    submitMissingNCR = async () => {
        this.setState({
            missingNCRStatus: "Loading..."
        });
        
        // Get the form entry data
        await DataService.getFormEntry(this.state.missingNCR.companyID, this.state.missingNCR.formEntryID)
            .then((response) => {
                // Check for error while getting form entry (return if error)
                if (response.status >= 300) {
                    this.setState({
                        missingNCRStatus: `Error occurred getting the form entry from FOF API (HTTP status code: ${response.status})`
                    });
                    return;
                }

                // Invalid company ID
                if (response.data.integrationKey === null) {
                    this.setState({
                        missingNCRStatus: "Invalid 'Company ID' value"
                    });
                }
                // Invalid form entry ID
                else if (response.data.entry === null) {
                    this.setState({
                        missingNCRStatus: "Invalid 'Form Entry ID' value"
                    });
                }
                // Valid input
                else {
                    // Submit form entry
                    FormEntryService.submit(response.data)
                        .then((result) => {
                            // Check for error while submitting form entry (return if error)
                            if (result.status >= 300) {
                                this.setState({
                                    missingNCRStatus: `Error occurred submitting the form entry to Blattner Portal (HTTP status code: ${result.status})`
                                });
                                return;
                            }

                            // Check if already exists
                            if (result.data.includes("already exists")) {
                                this.setState({
                                    missingNCRStatus: "This form entry already exists"
                                });
                            }
                            // Success
                            else {
                                this.setState({
                                    missingNCRStatus: "Success!"
                                });
                            }
                        });
                }
            });
    }

    ncrActionList = () => {
        const showNcrList = () =>
            this.ncrList.map((ncr) => {
                let ncrSubmission = this.state.ncrSubmissionList?.find((n) => n.value === ncr);
                return (
                    <div>
                        <div className='row py-1'>
                            <div className='col-2 mt-2 border-bottom'>
                                {ncr}
                            </div>
                            <div className='col-1 border-bottom py-1'>
                                <Button type="button" size="sm" variant="outline-secondary" onClick={() => {this.processNCR(ncr)}}>
                                    {(!ncrSubmission?.running && (!ncrSubmission || !ncrSubmission.success)) && <>run</>}
                                    {(!ncrSubmission?.running && (ncrSubmission?.success)) && <>done</>}
                                    {(ncrSubmission?.running) && <>running</>}
                                </Button>
                            </div>
                            {(ncrSubmission) && <div className='col-1 border-bottom mt-1 py-1'>
                                {(!ncrSubmission.success) && <CircleX />}
                                {(ncrSubmission.success) && <CircleCheck />}
                            </div>}
                        </div>
                    </div>
                );
            });
        return (
            <>
                {showNcrList()}
            </>
        );
    }

    allowNcrAccess() {
        if (!this.props.userData) {
            return false;
        }
        return checkIfOneOffNCR(this.props.userData);
    }

    allowSpecialAccess() {
        if (!this.props.userData) {
            return false;
        }
        return (reliabilityEngineerAccess(this.props.userData.jobCode)
            || developerAccess(this.props.userData.jobCode));
    }

    allowDataSourceRulesAccess() {
        if (!this.props.userData) {
            return false;
        }
        return hasRuleEditorAccess(this.props.userData);
    }

    adGroupSelection() { 
        const { 
           selectOptionsGroup,
           selectedOptionsGroup,
           defaultGroupValues
        } = this.state;

        return (
            <div
                className='row' style={{height: '70vh'}}
            >
                <div className='col'>
                    <div
                        className='row h5'
                        style={{height: '3vh'}}
                    >
                        You are a part of these groups:
                    </div>
                    <div
                        className='row mt-3 overflow-auto border border-light rounded shadow-sm'
                        style={{height: '50vh'}}
                    >
                        {(this.state.defaultGroupValues.length > 0) ? (
                            <div>
                                {defaultGroupValues.map((val) => (
                                    <div
                                        key={`${val.id}`}
                                        className='border border-light rounded px-2 py-1'
                                    >
                                        {val.group}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className=''>
                                No Groups Selected
                            </div>
                        )}
                    </div>
                    <div className='row h5 mt-3'
                        style={{height: '15vh'}}
                    >
                        <div className='row'>
                            Select new groups to change:
                        </div>
                        <div className='row mt-2'>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti = {true}
                                onChange={(option: any) => this.handleChangeSelectGroup(option)}
                                options={selectOptionsGroup}
                            />
                        </div>
                        <div className='row text-center'>
                            <div className='col w-25 mx-1 my-1'>
                                <Button
                                    type="button"
                                    className='btn btn-sm'
                                    onClick={ () => {
                                        this.sendGroupsToAPI(selectedOptionsGroup, this.context.accounts[0].username)
                                    }}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    jobSelector = () => {
        const updateJobCode = (e: any) => {
            const selectedJobCode = e.target.value;
            if (this.props.updateTestJobCode) {
                this.props.updateTestJobCode(selectedJobCode);
            }
            this.setState({ selectedJobCode: selectedJobCode });
        }
        return (
        <div
            className='row' style={{height: '70vh'}}
        >
            <div>
                <SelectField
                    dataName="JobCodeSelect"
                    title="Job Code"
                    options={JobCodeList.map((jobCode) => ({label: `${jobCode.name} - ${jobCode.code}`, value: jobCode.code}))}
                    value={this.state.selectedJobCode}
                    onchange={updateJobCode}
                />
            </div>
        </div>
        )
    }

    handleDataSourceChange = (value: string) => {
        this.setState({
            selectedDataSource: value,
        })
    }

    selectDataSourceHeaders = (headers: DataSourceResponse) => {
        if(!headers) {
            return;
        }
        return (
            <DataSourceDropDown
                dataSourceList={headers.dataSources}
                selectionHandler={this.handleDataSourceChange}
                cookiesName='dataSource'
            />
        );
    };

    resetCacheClick = async () => {
        if (!this.state.selectedDataSource || !this.props.selectedProject) {
            return;
        }
        this.setState({
            resettingCache: true,
            resettingCacheError: false
        });
        try {
            const { status, statusText } = await DataService.clearDataSourceCache(this.props.selectedProject, this.state.selectedDataSource)

            if (status !== 200) {
                throw new Error(`Error resetting cache with ${status}: ${statusText}`);
            }
            const msg = (
                <div>
                    {`Cleared Cache for ${this.state.selectedDataSource} data source in environment ${this.props.selectedProject}`}
                </div>
            )
            successToast(msg)
        } catch (error) {
            this.setState({
                resettingCacheError: true
            })
            const msg = (
                <div>
                    {`Error Clearing Cache for ${this.props.selectedProject}, ${this.state.selectedDataSource}: "${error}"`}
                </div>
            )
            errorToast(msg)
        } finally {
            this.setState({
                resettingCache: false
            });
        }
    }


    DSManagment = () => {
        if (!this.props.projectSelector) {
            return (
                <div>
                    something is wrong );
                </div>
            )
        }
        const handleButtonColor = () => {
            if (this.state.resettingCacheError) {
                return 'btn-danger'
            }
            return 'btn-primary';
        }

        return (
            <div className='col'>
                <div className='row'>
                    {this.props.projectSelector}
                </div>
                <div className='row'>
                    <LoadingWrapper showLoading={this.state.headersLoading}>
                        {this.state.dataSourceHeaders && this.selectDataSourceHeaders(this.state.dataSourceHeaders)}
                    </LoadingWrapper>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div>
                            <b>Reset Cache</b>
                        </div>
                        <button
                            type='button'
                            className={`btn ${handleButtonColor()}`}
                            title='reset cache for given project and data source'
                            onClick={() => {this.resetCacheClick()}}
                            disabled={this.state.selectedDataSource === ''}
                        >
                            <LoadingWrapper showLoading={this.state.resettingCache}>
                                Reset Cache
                            </LoadingWrapper>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='row w-75' style={{height: '85vh'}}>
                <div>
                    <div className='col'>
                        <div className='row' style={{height: '10vh'}}>
                            <div className='col-3'>
                            </div>
                            <div className='col-6 text-center h1 my-3'>
                                Admin Page
                            </div>
                            <div className='col-3 my-3'>
                            </div>
                        </div>
                        <div className='row' style={{height: '2vh'}}>
                            <div className="square"></div>
                        </div>
                        {(this.allowSpecialAccess()) &&
                            <div className='row mb-3'>
                                <div className='col border-end'>
                                    {this.DSManagment()}
                                </div>
                                <div className='col border-end'>
                                    {(this.allowNcrAccess()) &&
                                        <>
                                            {this.ncrActionList()}
                                            <h4><b><u>Submit Missing NCR</u></b></h4>
                                            <TextField
                                                dataName={"formEntryID"}
                                                title={"Company ID"}
                                                value={this.state.missingNCR.companyID}
                                                onchange={this.updateMissingNCRCompanyID}
                                            />
                                            <TextField
                                                dataName={"formEntryID"}
                                                title={"Form Entry ID"}
                                                value={this.state.missingNCR.formEntryID}
                                                onchange={this.updateMissingNCRFormEntryID}
                                            />
                                            <ButtonType
                                                dataName={"submitNCR"}
                                                buttonText={"Submit"}
                                                eventHandler={this.submitMissingNCR}
                                            />
                                            <br />
                                            <p>{this.state.missingNCRStatus}</p>
                                        </>
                                    }
                                </div>
                                <div className='col text-center'>
                                </div>
                            </div>
                        }
                        {(this.allowSpecialAccess()) &&
                            <div className='row' style={{height: '2vh'}}>
                                <div className="square"></div>
                            </div>
                        }
                        <div className='row' style={{height: '70vh'}}>
                            <div className='row'>
                                <div className='col-9'>
                                </div>
                                <div className='col-3'>
                                    <div
                                        className='form-check form-switch'
                                        title='toggles whether or not to use the test user'
                                    >
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            role='switch'
                                            onChange={() => {
                                                if(this.props.updateUseTestUser) {
                                                    this.props.updateUseTestUser()
                                                }
                                            }}
                                            checked={this.props.usingTestUser}
                                        />
                                        <label className='form-check-label'>Test User</label>
                                    </div>
                                </div>
                            </div>
                            <div
                                className='col'
                            >
                                {this.adGroupSelection()}
                            </div>
                            <div className='col'>
                                {this.jobSelector()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminPage