import { useEffect, useState } from 'react';
import { Badge, Collapse } from 'react-bootstrap';
import Select from 'react-select';

import Editable from './Icons/Editable';
import Uneditable from './Icons/Uneditable';

import ListItem from '../types/ListItem';

/**
 * The EditListItem component could be broken out into it's own file.
 *   Really the only reason it is not is time.
 */
type EditListItemProps = {
    item: ListItem,
    onClick: (item: ListItem) => void,
    disabled: boolean,
};

const EditListItem = ({ item, onClick, disabled }: EditListItemProps) => {
    const handleOnClick = () => {
        onClick(item);
    }
    return (
        <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={handleOnClick}
            disabled={disabled}
            title='click to remove'
        >
            {item.label}
        </button>
    );
}

type EditableListProps = {
    listTitle: string,
    initialValues: ListItem[],
    masterValues?: ListItem[],
    onChange?: (items: ListItem[]) => void,
    editable: boolean,
}

const EditableList = ({ listTitle, initialValues, masterValues, onChange, editable }: EditableListProps) => {

    const defaultItem = {value: 'none', label: 'Select'};

    const [currentItem, setCurrentItem] = useState<ListItem>(defaultItem)
    const [currentList, setCurrentList] = useState<ListItem[]>(initialValues);
    const [myKey, setMyKey] = useState(listTitle + Date.now())
    const [editing, setEditing] = useState<boolean>(false);
    const [collapse, setCollapse] = useState<boolean>(true);

    // used to ensure that the editability is managed in a way that is consistent
    const toggleCollapse = () => {
        if(!collapse) {
            setEditing(false);
        }
        setCollapse(!collapse)
    };

    // used to ensure that the editability is managed in a way that is consistent
    const toggleEditing = () => {
        if(!editing) {
            setCollapse(false)
        }
        setEditing(!editing);
    };

    // this is used to catch changes to the list and then execute the onChange
    //   for this component
    useEffect(() => {
        if(!editing || !onChange) {
            return;
        }
        onChange(currentList);
    }, [currentList])

    // catch clicks on list items for deletion
    const handleItemClick = (item: ListItem) => {
        if (!currentList) {
            return;
        }
        let newList = [...currentList];
        const index = newList.findIndex((value) => value.value === item.value) ?? -1;
        if (index < 0) {
            return;
        }
        newList.splice(index, 1);
        setCurrentList(newList);
        setMyKey(listTitle + Date.now())
    }

    // catch clicks to the add button to add items to the list
    const handleAddClick = () => {
        if (currentItem.value === defaultItem.value) {
            return;
        }
        const newList = [...currentList]
        const newItem = currentItem;
        newList.push(newItem);
        setCurrentList(newList.sort((a, b) => (a.value < b.value) ? -1 : (a.value > b.value) ?  1 : 0));
        setCurrentItem(defaultItem);
        setMyKey(listTitle + Date.now())
    }

    // allows filtering of the list for the Select component
    const filterOptions = () => {
        if (!masterValues) {
            return;
        }
        return masterValues.filter((mItem) => (
            currentList.find((item) => (item.value === mItem.value)) === undefined)
        );
    }

    // catch click on an item in the Select component
    const handleSelectedItemChange = (item: ListItem | null) => {
        if (!item) {
            return;
        }
        setCurrentItem(item);
    }

    // used to simplify a className callout for the edit button
    const hideButton = (hide: boolean) => {
        return (hide ? 'visually-hidden' : '');
    }

    return(
        <div className='col border p-2 m-2 shadow-sm editable-list-tile rounded-2'>
            <div className='row'>
                <div className='row d-flex align-items-center'>
                    <div className='col-4' onClick={() => {toggleCollapse()}}>
                        <b>{listTitle}</b>
                    </div>
                    <div className='col-7 d-flex justify-content-left' onClick={() => {toggleCollapse()}}>
                        <Badge bg='secondary'>
                            {currentList.length}
                        </Badge>
                    </div>
                    <div className='col-1 d-flex justify-content-center'>
                        <button
                            className={`btn ${hideButton(!editable)}`}
                            title={editing ? 'click to stop editing' : 'click to allow editing'}
                            disabled={!editable}
                            onClick={() => {toggleEditing()}}
                        >
                            {editing ? <Editable /> : <Uneditable />}
                        </button>
                    </div>
                </div>
            </div>
            <Collapse
                in={!collapse}
            >
                <div>
                    <div
                        className='row border shadow-sm m-2 p-2 editable-list rounded-2'
                        key={myKey}
                    >
                        {(currentList.length === 0) && 'None'}
                        {(currentList.length !== 0) && currentList
                            .map((item) => (
                                <div
                                    key={item.value}
                                >
                                    <EditListItem
                                        item={item}
                                        onClick={(i: ListItem) => handleItemClick(i)}
                                        disabled={!editing}
                                    />
                                </div>
                            ))}
                    </div>
                    <div
                        className='row d-flex align-items-center'
                    >
                        <div className='col-9'>
                            <Select
                                isDisabled={!editing}
                                name={listTitle}
                                options={filterOptions()}
                                isSearchable
                                onChange={handleSelectedItemChange}
                                value={currentItem}
                            />
                        </div>
                        <div className='col'>
                            <button
                                disabled={!editing}
                                className='btn btn-outline-secondary'
                                onClick={handleAddClick}
                                title='click to add selected item to the list'
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
}

export default EditableList;