import { Badge } from 'react-bootstrap';

import CircleX from "../Icons/CircleX";
import Copy from "../Icons/Copy";

import Environment from "../../types/Data/Environment";

type Props = {
    whereUsedList: Environment[],
    onProjectClick: (environmentID: string) => void | undefined
    onDeleteClick: (environmentID: string) => void | undefined,
    onCopyClick: (environmentID: string) => void | undefined,
};

const defaultProps = {
    onProjectClick: () => {},
    onDeleteClick: undefined,
    onCopyClick: undefined,
}

const WhereUsedRulesDisplay = ({ whereUsedList, onProjectClick, onDeleteClick, onCopyClick }: Props) => (
    <div className='col border shadow rounded-2'>
        <div className='row ps-3 pt-2'>
            <div className='col-4'>
                <b>
                    Where Used
                </b>
            </div>
            <div className='col'>
                <Badge bg='secondary'>
                    {whereUsedList.length}
                </Badge>
            </div>
        </div>
        <div className='row border p-2 m-2 shadow-sm rounded-2'>
            {
                (whereUsedList.length === 0) ?
                <div>
                    <button
                        className='btn btn-sm'
                        title='No other project has rules for this data source'
                    >
                        No other rules found
                    </button>
                </div>
                :
                whereUsedList.map((project, i) => (
                    <div
                        className={`${(i !== 0) ? 'border-top' : ''}`}
                        key={`${project.name}${project.id}`}
                    >
                        <div className='row'>
                            <div className='col-8'>
                                <button
                                    className='btn btn-sm'
                                    title={`Switch to '${project.name}'`}
                                    onClick={() => onProjectClick(project.id)}
                                >
                                    {project.name}
                                </button>
                            </div>
                            <div className='col-1 mb-1'>
                            </div>
                            <div className='col-1 mb-1 d-flex justify-content-center'>
                                {
                                    onCopyClick !== undefined &&
                                    <button
                                        className='btn btn-sm'
                                        title={`Clone rule in '${project.name}' to current project`}
                                        onClick={() => onCopyClick(project.id)}
                                    >
                                        <Copy color='DimGray' />
                                    </button>
                                }
                            </div>
                            <div className='col-1 mb-1 d-flex justify-content-center'>
                                {
                                    onDeleteClick !== undefined &&
                                    <button
                                        className='btn btn-sm'
                                        title={`Delete rule in '${project.name}'`}
                                        onClick={() => onDeleteClick(project.id)}
                                    >
                                        <CircleX color='DimGray' />
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
);

WhereUsedRulesDisplay.defaultProps = defaultProps;

export default WhereUsedRulesDisplay;