import ExclamationTriangle from '../../components/Icons/ExclamationTriangle';
import UnderConstruction from '../../components/Icons/UnderConstruction';

const blattnerBlue = '#02569a';
const bootstrapWarning = '#FFC107';

const RuleEditorHelp = () => (
    <div>
        <h5>Rule Scope</h5>
            <p>
                &nbsp;&nbsp;The <i>Rule Scope</i> of the project (whether it is considered "Global" or "Project Specific") is controlled via the "Project Specific" check box.<br/>
            </p>
            <ul>
                <li>
                    When unchecked the project is considered a "Global" data source.
                </li>
                <li>
                    Data Sources that are Global will be saved to all active and warranty active projects in Blattner Mobile
                </li>
                <li><ExclamationTriangle color={bootstrapWarning}/>&nbsp;<b>NOTE:</b>&nbsp;<ExclamationTriangle color={bootstrapWarning}/>&nbsp;
                    When saving as a global rule, all rules in other projects that are associated with this data source<br />
                    will be removed and a single entry will be added to the Dev V.03 environment.<br />
                    Once a global rule set is saved, any further updates for Global Data Sources should be done in Dev V.03.
                </li>
            </ul>
        <h5>Visibility</h5>
            <p>
                &nbsp;&nbsp;The <i>Visibility</i> is used to allow users to be able to select and view the data source in Blattner Portal<br />
                &nbsp;&nbsp;<b>Note:</b>&nbsp; In order to be able to edit the data source you also need to set the visibility.
            </p>
        <div className='text-center'>
            <div>
                <h2>
                    Under Construction
                </h2>
            </div>
            <div>
                <UnderConstruction size={'200'} />
            </div>
            <div>
                <h4>
                    Come Back Soon!
                </h4>
            </div>
        </div>
        <div>
            <h6>
                Deleting Rules
            </h6>
        </div>
    </div>
);

export default RuleEditorHelp;