import '../../styles/CirclePending.css'

type Props = {
    size: string,
    color: string,
    animate: boolean,
} & typeof defaultProps;

const defaultProps = {
    size: '36',
    color: '#02569a',
    animate: false,
}
/*
 * Souce: Blattner Mobile dev Team
 */
const CirclePending = ({ size, color, animate }: Props) => {
    // standard attributes
    const sizeNum = parseInt(size);
    const stroke = sizeNum / 16;
    const viewbox = `0 0 ${size} ${size}`;

    // circle dimensions 
    const circleX = (sizeNum / 2);
    const circleR = (sizeNum / 2) - (stroke / 2);

    // bar dimentions
    const barBaseHeight = animate ? 12 : 4
    const barHeight = ((barBaseHeight * sizeNum)/ 36);
    const barWidth = ((4 * sizeNum) / 36);
    const barY = (sizeNum - barHeight) / 2;
    const barMiddleX = (sizeNum - barWidth) / 2;
    const barLeftX = barMiddleX - (sizeNum / 4)
    const barRightX = barMiddleX + (sizeNum / 4)

    return (
        <svg
            fill={color}
            height={size}
            width={size}
            viewBox={viewbox}
        >
            <circle r={circleR} cx={circleX} cy={circleX} stroke={color} strokeWidth={stroke} fill="none" />
            <rect id={animate ? "loading-bar-left" : ""} width={barWidth} height={barHeight} x={barLeftX} y={barY}/>
            <rect id={animate ? "loading-bar-middle" : ""} width={barWidth} height={barHeight} x={barMiddleX} y={barY} />
            <rect id={animate ? "loading-bar-right" : ""} width={barWidth} height={barHeight} x={barRightX} y={barY} />
        </svg>
    )
}



CirclePending.defaultProps = defaultProps;

export default CirclePending;