import { Component } from 'react';
import { Modal, Spinner, Table, Fade } from 'react-bootstrap';
import { appInsights } from '../services/appInsights';
import { MsalContext } from "@azure/msal-react";
import DashboardService from '../services/DashboardService';
import RoleGroupService from '../services/RoleGroupService';
import IProjectList from '../types/Dashboard/ProjectList';
import ButtonType from '../components/ButtonType';
import AddButton from '../components/AddButton';
import Delayed from '../components/Delayed';
import ITestGroup from '../types/TestGroup';
import { RoleGroup } from '../enums/RoleGroup';
import EmployeeService from '../services/EmployeeService';
import RoleGroupMember from '../types/RoleGroup/RoleGroupMember';
import ADSearchField from '../components/ADSearchField';
import RoleGroupList from '../types/RoleGroup/RoleGroupList';
import ProjectDropdown from '../components/ProjectDropdown';
import LoadingWrapper from '../components/LoadingWrapper';

type Props = {
    updateProject?: (e: any) => void;
    canEdit: boolean
}

type State = {
    userGroups: ITestGroup[];
    canEdit: boolean; 
    projectLoading: boolean;
    selectedProject: string;
    projectList: IProjectList;
    roleGroup: RoleGroup;
    groupLoading: boolean;
    groupMemberUpdates: RoleGroupList;
    changesMade: boolean; 
    addedEmployee: RoleGroupMember;
    userAlreadyAdded: boolean;
    openModal: boolean;
    modalType: string;
    saveLoading: boolean;
    saveStatus: string;
    showSaveStatus: boolean;
}

// Constant variables for Save Button Visuals
const SAVEERROR = "SaveError";
const SAVESUCCESS = "SaveSuccess"

export default class DOTCoordinatorsPage extends Component<Props, State> {
    static contextType = MsalContext;
    constructor(props: any) {
        super(props);

        this.state = {
            userGroups: [],
            canEdit: this.props.canEdit,
            projectLoading: true,
            selectedProject: "",
            projectList: {
                projects: [],
                defaultProjectID: ""
            },
            roleGroup: 1,
            groupLoading: false,
            groupMemberUpdates: {
                addUpdates: [],
                removeUpdates: [],
                updatedGroup: [],
                companyID: "",
                groupName: 1,
                modifiedUser: ""
            },
            changesMade: false,
            addedEmployee: {
                employeeID: "",
                employeeName: ""
            },
            userAlreadyAdded: false,
            openModal: false,
            modalType: "",
            saveLoading: false,
            saveStatus: "",
            showSaveStatus: false
        }
    }
    
    async componentDidMount() {
        appInsights.trackPageView({ name: "DOT Coordinators Page" });
        this.getProjects();
    }

    async getProjects() {
        await DashboardService.getProjects(this.context.accounts[0].name)
            .then((response) => {
                this.setState({
                    projectLoading: false,
                    projectList: response.data
                });
                if (response.data.defaultProjectID && !this.state.selectedProject) {
                    this.setState({
                        selectedProject: response.data.defaultProjectID
                    });
                }
            }).catch((e) => {
                this.setState({
                    projectLoading: false
                });
            });
    }

    async getGroupMembers(project: string, group: RoleGroup) {
        const userEmail = this.context.accounts[0].username;

        await RoleGroupService.getGroupMembers(project, group, userEmail)
        .then((response) => {
            if (response.data) {
                var newGroupMemberUpdates = this.state.groupMemberUpdates;
                newGroupMemberUpdates.updatedGroup = response.data.roleGroupMembers;

                this.setState({
                    groupMemberUpdates: newGroupMemberUpdates,
                    groupLoading: false
                });
            }
        });
    }

    addMemberLocal = (member: RoleGroupMember) => {
        // If user is in removeUpdates then remove from removeUpdates, else add to addUpdates
        var newGroupMemberUpdates = this.state.groupMemberUpdates;
        var newMemberToRemove = this.state.groupMemberUpdates.removeUpdates;

        if (newGroupMemberUpdates.removeUpdates.some(x => x.employeeID === member.employeeID)) {
            // Removing from removeUpdates
            var removeIndex = newMemberToRemove.map(x => x.employeeID).indexOf(member.employeeID);
            newMemberToRemove.splice(removeIndex, 1);

            var updateIndex = newGroupMemberUpdates.removeUpdates.map(x => x.employeeID).indexOf(member.employeeID);
            newGroupMemberUpdates.removeUpdates.splice(updateIndex, 1);
        }
        else {
            // Adding to addUpdates
            newGroupMemberUpdates.addUpdates.push(member);
        }

        // Adding to local list (updatedGroup)
        newGroupMemberUpdates.updatedGroup.push(member);

        // Check for updates
        var update = false;
        if (newGroupMemberUpdates.addUpdates.length > 0) {
            update = true;
        }

        this.setState({
            openModal: false,
            groupMemberUpdates: newGroupMemberUpdates,
            changesMade: update,
            addedEmployee: {
                employeeID: "",
                employeeName: ""
            }
        });
    }

    removeMemberLocal(member: RoleGroupMember) {
        // If user is in addUpdates then remove from addUpdates, else add to removeUpdates
        var newGroupMemberUpdates = this.state.groupMemberUpdates;
        var newMemberToAdd = this.state.groupMemberUpdates.addUpdates;

        if (newGroupMemberUpdates.addUpdates.some(x => x.employeeID === member.employeeID)) {
            // Removing from addUpdates
            var addIndex = newMemberToAdd.map(x => x.employeeID).indexOf(member.employeeID);
            newMemberToAdd.splice(addIndex, 1);

            var updateIndex = newGroupMemberUpdates.addUpdates.map(x => x.employeeID).indexOf(member.employeeID);
            newGroupMemberUpdates.addUpdates.splice(updateIndex, 1);
        }
        else {
            // Adding to removeUpdates
            newGroupMemberUpdates.removeUpdates.push(member);
        }

        // Removing from local list (updatedGroup)
        var newMemberList = this.state.groupMemberUpdates.updatedGroup;
        var memberIndex = newMemberList.indexOf(member);
        newMemberList.splice(memberIndex, 1);
        newGroupMemberUpdates.updatedGroup = newMemberList;

        // Check for updates
        var update = false;
        if (newGroupMemberUpdates.removeUpdates.length > 0) {
            update = true;
        }

        this.setState({
            groupMemberUpdates: newGroupMemberUpdates,
            changesMade: update
        });
    }

    saveUpdates = async () => {
        this.setState({
            saveLoading: true,
            changesMade: false,
            showSaveStatus: true
        });
    
        // Close modal
        this.onCloseModal();
        // Add and remove members if there are any in the lists
        if (this.state.groupMemberUpdates.addUpdates.length > 0 || this.state.groupMemberUpdates.removeUpdates.length > 0) {
            await RoleGroupService.updateGroupMembers(this.state.groupMemberUpdates)
            .then((response)=>{
                if(response.status >= 100 && response.status <=399){
                    this.setState({
                        saveStatus: SAVESUCCESS,
                        saveLoading: false
                    });
                }
                else {
                    this.setState({
                        saveStatus: SAVEERROR,
                        saveLoading: false
                    });
                }
                //Set showSaveStatus back to false after 10 seconds
                setTimeout(()=>{this.setState({showSaveStatus: false})}, 10000)
            })
        }

        // Reset state fields
        var newGroupMemberUpdates = this.state.groupMemberUpdates;
        newGroupMemberUpdates.addUpdates = [];
        newGroupMemberUpdates.removeUpdates = [];

        this.setState({
            groupMemberUpdates: newGroupMemberUpdates,
            changesMade: false
        });
    }

    onOpenModal = (type: string) => {
        this.setState({
            openModal: true,
            modalType: type
        });
    }

    onCloseModal = () => {
        this.setState({
            openModal: false,
            modalType: "",
            addedEmployee: {
                employeeID: "",
                employeeName: ""
            },
            userAlreadyAdded: false
        });
    }

    handleAddUserChange = async (value: string) => {
        // Reset userAlreadyAdded to false
        this.setState({ userAlreadyAdded: false });

        if (value) {
            await EmployeeService.getNameByID(value)
            .then((response =>
                this.setState({
                    addedEmployee: {
                        employeeID: value,
                        employeeName: response.data
                    }
                })
            ));
        }
        else {
            this.setState({
                addedEmployee: {
                    employeeID: "",
                    employeeName: ""
                }
            });
        }

        // Check if person is already in the group, set userAlreadyAdded to true if true
        this.state.groupMemberUpdates.updatedGroup.map((member) =>
            member.employeeID === value ? this.setState({ userAlreadyAdded: true }) : null);
    }

    handleProjectChange = (project: string) => {
        if(this.props.updateProject) {
            this.props.updateProject(project);
        }
        this.setState({
            selectedProject: project,
            groupMemberUpdates: {
                addUpdates: [],
                removeUpdates: [],
                updatedGroup: [],
                companyID: project,
                groupName: 1,
                modifiedUser: this.context.accounts[0].username
            },
            userAlreadyAdded: false,
            changesMade: false,
            groupLoading: true
        });

        this.getGroupMembers(project, this.state.roleGroup);
    }

    render() {
        const {
            canEdit,
            projectLoading,
            projectList,
            selectedProject,
            groupLoading,
            groupMemberUpdates,
            changesMade,
            addedEmployee,
            userAlreadyAdded,
            openModal,
            modalType,
            showSaveStatus,
            saveLoading,
            saveStatus
        } = this.state;

        const CurrentGroupMembers = groupLoading ?
            <tr>
                <td colSpan={100}>
                    <Delayed waitBeforeShow={750}>
                        <div className="DivPadded DivCenter"><Spinner animation="border" role="status" variant="primary" /></div>
                    </Delayed>
                </td>
            </tr> :
                groupMemberUpdates.updatedGroup.length > 0 ?
                    groupMemberUpdates.updatedGroup.map((groupMember) =>
                        <tr key={`${groupMember.employeeID}-row`}>
                            <td key={groupMember.employeeID}>{groupMember.employeeName} ({groupMember.employeeID})</td>
                            {canEdit ?
                            <td><ButtonType eventHandler={() => this.removeMemberLocal(groupMember)} dataName="removeMember" buttonText="x" color="red" size="sm" /></td>
                            : <td></td>}
                        </tr>
                    ) :
                    <tr>
                        <td colSpan={100}>
                            <b>No Group Members Found</b>
                        </td>
                </tr>;

        return(
            <div className='w-75'>
                <LoadingWrapper showLoading={projectLoading} loadingMessage="Getting DOT Coordinators..." windSpinner windSpinnerScale={3}>
                    <Modal show={openModal} onHide={this.onCloseModal} centered>
                        {modalType === "Add" ? 
                        <div>
                            <Modal.Header closeButton>
                                <Modal.Title>Add DOT Coordinator:</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <ADSearchField onchange={this.handleAddUserChange} dataName="employeeSearch" title="Select an Employee" />
                                    <br></br>
                                    {userAlreadyAdded ? 
                                        <div>
                                            <b>This person is already in the group.</b>
                                            <br />
                                        </div>
                                    : null}
                                    <br />
                                    <div className="DivCenter">
                                        <ButtonType
                                            eventHandler={() => this.addMemberLocal(this.state.addedEmployee)}
                                            dataName="addMember"
                                            buttonText="Add"
                                            color="blue"
                                            readOnly={addedEmployee.employeeID === "" || userAlreadyAdded}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                        </div>
                        : null}
                        {modalType === "Save" ? 
                        <div>
                            <Modal.Header closeButton>
                                <Modal.Title>Are you sure you want to save?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    {groupMemberUpdates.addUpdates.length > 0 ?
                                        <div>
                                            <p><b>Add the following individual(s):</b></p>
                                            <div>
                                                <ul>
                                                    {groupMemberUpdates.addUpdates.map((member) =>
                                                    <li key={member.employeeID}>{member.employeeName} ({member.employeeID})</li>
                                                    )}
                                                </ul>
                                            </div>
                                            <br></br>
                                        </div>
                                    : null}
                                    {groupMemberUpdates.removeUpdates.length > 0 ?
                                        <div>
                                            <p><b>Remove the following individual(s):</b></p>
                                            <div>
                                                <ul>
                                                    {groupMemberUpdates.removeUpdates.map((member) =>
                                                    <li key={member.employeeID}>{member.employeeName} ({member.employeeID})</li>
                                                    )}
                                                </ul>
                                            </div>
                                            <br></br>
                                        </div>
                                    : null}
                                    <div className="DivCenter">
                                        <ButtonType eventHandler={this.saveUpdates} dataName="confirmSave" buttonText="Confirm" color="blue" />
                                    </div>
                                </div>
                            </Modal.Body>
                        </div>
                        : null}
                    </Modal>

                    <ProjectDropdown projectList={projectList} cookieName='projects' selectedProjectHandler={this.handleProjectChange} />

                    <h1 className="header mt-5 mb-4 text-center">DOT Coordinator(s)</h1>

                    {selectedProject ?
                        <div>
                            <div id="RightAlignedFloatDiv">
                                {canEdit ?
                                    changesMade ?
                                        <ButtonType dataName="save" color="blue" buttonText="Save" type="submit" eventHandler={() => this.onOpenModal("Save")} />
                                    : saveLoading ? 
                                        <div className="DivPadded DivCenter"><Spinner animation="border" role="status" variant="primary" /></div>
                                    : saveStatus === SAVESUCCESS ?
                                        <div className="DivPadded DivCenter">
                                            <Fade in={showSaveStatus} >
                                                <b>Save Successful ✔</b>
                                            </Fade>
                                        </div>
                                    : saveStatus === SAVEERROR ?
                                        <div className="DivPadded DivCenter">
                                            <b>An Error Occurred ⚠️</b><p><small><i>Please Refresh and Try Again</i></small></p>
                                        </div>
                                    : null
                                : null}
                            </div>
                            {canEdit ?
                            <div>
                                { groupMemberUpdates.updatedGroup.length < 5 ? 
                                    <AddButton eventHandler={() => this.onOpenModal("Add")} dataName="DOT" title="DOT Coordinator" readOnly={groupMemberUpdates.updatedGroup.length > 4} />
                                    : null }
                                <br />
                                {/* Hint Text */}
                                <p><i>A maximum of 5 individuals can be added.</i></p>
                                <p><i>The individuals identified also need to be added to the project in Blattner Mobile to be added to the DOT Coordinator group and receive Vehicle Inventory Checklist and DVIR tasks. </i></p>
                                <p><i>Upon saving, the changes are emailed to fleetsafety@blattnercompany.com.</i></p>
                            </div>
                            : null}
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{width: 575}}>Group Members</th>
                                        <th style={{width: 25}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CurrentGroupMembers}
                                </tbody>
                            </Table>
                        </div>
                    : null}
                </LoadingWrapper>
            </div>
        );
    }
}