import { useEffect, useState } from 'react';
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { Switch, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { loginRequest } from "./authConfig";
import NavbarTop from './components/Navbar';
import ProjectDropDown from './components/ProjectDropdown';
import AdminPage from './pages/AdminPage';
import TasksPage from './pages/TasksPage';
import TaskDetailsPage from './pages/TaskDetailsPage';
import Dashboard from './pages/Dashboard';
import DashboardTaskDetails from './pages/DashboardTaskDetails';
import DataEditorHelp from './pages/DataSource/DataEditorHelp';
import RuleEditorHelp from './pages/DataSource/RuleEditorHelp';
import DataEditorPage from './pages/DataSource/DataEditorPage';
import RuleEditorPage from './pages/DataSource/RuleEditorPage';
import DOTCoordinatorsPage from './pages/DOTCoordinatorsPage';
import ProjectGoalsPage from './pages/ProjectGoalsPage';
import QualityFormApproversPage from './pages/QualityFormApproversPage';
import UserInfoPage from './pages/UserInfoPage';
import ITask from './types/Task/Task';
import ITestGroup from './types/TestGroup';
import IProject from './types/Dashboard/Project';
import IProjectList from "./types/Dashboard/ProjectList";
import IDataSourceQuery from "./types/Data/DataSourceQuery";
import IDataSourceQueryList from "./types/Data/DataSourceQueryList";
import IDataSourceEmployeeInfo from './types/Employee/DataSourceEmployeeInfo';
import EnvironmentData from './types/FormsOnFire/EnvironmentData';
import { EnvironmentQuery, MetaDataQuery } from './types/FormsOnFire/EnvironmentQuery';
import ListItem from './types/ListItem';
import DashboardService from "./services/DashboardService";
import DataService from './services/DataService';
import FormsOnFireService from './services/FormsOnFire';
import TaskService from './services/TaskService';
import TestService from './services/TestService';
import EmployeeService from './services/EmployeeService';
import { hasRuleEditorAccess, hasAnyAdminAccess } from './functions/AdminPageAccess';
import {
    DOTCoordinatorJobCodes,
    DOTEditorJobCodes,
    PMSMJobCodes,
} from './constants/DOTEditorsGroups';

var tasks: ITask[];

function App() {
  const { accounts } = useMsal();
  const location = useLocation();
  const noProjectID = "N/A";

  const isTest = (process.env.REACT_APP_IS_TEST ?? '') === 'true';
  const isDevelop = (process.env.REACT_APP_IS_DEVELOP ?? '') === 'true';

  async function getTasks(userEmail: string) {
    await TaskService.getTasks(userEmail)
    .then((response) => {
      tasks = response.data
    });
  }

  async function getSubcontractors() {
    await DataService.searchDataSourceData("BOOMI_SUBCONTRACTORS", "1", "");
  }

  function updateTasks(newTasks: ITask[]) {
    tasks = newTasks;
  }

  const initEmployeeInfo: IDataSourceEmployeeInfo = {
    employeeID: 0,
    employeeName: "",
    title: "",
    businessUnit: "",
    company: "",
    email: "",
    recordType: "",
    firstName: "",
    lastName: "",
    jobCode: "",
    employeeNameDisplay: "",
    adGroups: [],
    projects: [],
  }

  const initProjectList: IProjectList = {
    projects: [] as IProject[],
    defaultProjectID: noProjectID,
  }

  const email = accounts[0]?.username.toLowerCase(); // Lowercase avoids errors when comparing email to Blattner Employees DS

  const [projectNumber, setProjectNumberState] = useState<string>("");
  const [dseProjectNumber, setDseProjectNumber] = useState<string>();

  const [canEditDOT, setCanEditDOT] = useState(false);
  const [userData, setUserData] = useState<IDataSourceEmployeeInfo>(initEmployeeInfo);
  const [userAssignments, setUserAssignments] = useState<string[]>();

  const [testGroups, setTestGroups] = useState<ITestGroup[]>();
  const [testJobCode, setTestJobCode] = useState<string>('');
  const [useTestUser, setUseTestUser] = useState<boolean>(false);

  const [masterEmployeeList, setMasterEmployeeList] = useState<ListItem[]>([]);
  const [fetchingNames, setFetchingNames] = useState<boolean>(false);
  const [gettingUserData, setGettingUserData] = useState<boolean>(false);

  // these get the same thing
  const [projectList, setProjectList] = useState<IProjectList>(initProjectList);
  const [globalEnvironmentList, setGlobalEnvironmentList] = useState<EnvironmentData[]>();

  /**
   * get the global environments list
   * if this is dev or test only allow access to updating:
   *   - Dev Test
   *   - Dev V.03
   *   - Staging
   */
  useEffect(() => {
    const DevTestEnvironment: EnvironmentData = {
      "projectID": "68457",
      "projectNumber": "978",
      "projectName": "Dev Test"
    }

    const DevV03Environment: EnvironmentData = {
      projectID: "56824",
      projectName: "Dev V.03",
      projectNumber: "003",
    };

    const StagingEnvironment: EnvironmentData = {
      "projectID": "71545",
      "projectNumber": "978",
      "projectName": "Blattner Mobile Staging"
    }

    const getEnvironments = async() => {
      try {
        const mdQueryList: MetaDataQuery[] = [
                {
                    key: 'Project Status',
                    regex: 'Active',
                },
            ];
        const query: EnvironmentQuery = {
            metaDataQueryList: mdQueryList,
        }
        const { data } = await FormsOnFireService.getEnvironment(query);

        data.unshift(DevV03Environment)

        setGlobalEnvironmentList(data);
      } catch (ex) {
        console.log('Exception getting environment data:', ex)
      }
    }
    if (isDevelop || isTest) {
      setGlobalEnvironmentList([DevV03Environment, DevTestEnvironment, StagingEnvironment]);

    } else {
      getEnvironments();
    }
  }, []);

  /**
   * Using this to catch the page load and get the hits for BOOMI EMPLOYEES in order
   *   this will reduce the number of API hits and hopefully make thing run a little faster
   */
  useEffect(() => {
    if (fetchingNames || masterEmployeeList?.length > 0 || email === undefined) {
      return;
    }
    setFetchingNames(true);
    setGettingUserData(true);

    const stackCallsToBoomiEmployees = async () => {
      try {
        // get all users
        //  this puts the most time intensive DS pull up front
        const dsResponse = await DataService.getDataSource('56824', 'BOOMI_EMPLOYEES');
        const usersList: ListItem[] = [];
        dsResponse.data.rows
          .forEach((row) => {
            usersList.push({
              value: row[5], // email
              label: `${row[8]} ${row[9]} - ${row[0]}`, // first + last + employeeID
            })
          });
        setMasterEmployeeList(usersList.sort((a, b) => (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0));

        // get User information
        const userResponse = await EmployeeService.getDataSourceEmployeeInfo(email);
        const userInfo = userResponse.data
        userInfo.adGroups.sort((a, b) => (a.displayName < b.displayName)  ? -1 : (a.displayName > b.displayName) ? 1 : 0);
        setUserData(userInfo);

      } catch (error) {
        console.log('Error getting the employee info:', error)
      } finally {
        setFetchingNames(false);
        setGettingUserData(false);
      }
    }

    stackCallsToBoomiEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  useEffect(() => {
    const getTestGroups = async (userEmail: string) => {
      await TestService.getGroup(userEmail)
        .then((response) => {
          setTestGroups(response.data);
        });
    }

    getTestGroups(email);
  }, [email])

  /**
   * the next two useEffects can probably be consolidated into one because
   *   they both have the same dependency.
   */
  useEffect(() => {
    if (!userData.email) {
      return;
    }

    const getProjectList = async (userEmail: string) => {
      await DashboardService.getProjects(userEmail)
        .then((response) => {
          setProjectList(response.data);
        })
        .catch((error) => {
          console.log("Error getting the project list", error);
        })
    }

    getProjectList(userData.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  useEffect(() => {
    if (userData.employeeID === 0) {
      return;
    }

    const getUserAssignedProjects = async (employeeID: number) => {
      const query: IDataSourceQuery = {
        column: 0,
        values: [
          employeeID.toString(),
        ]
      }
      const queryList: IDataSourceQueryList = {
        query: [ query ]
      }
      await DataService.searchDataSourceDataWithQuery("BOOMI_ASSIGNMENTS", 3, queryList)
        .then((response) => {
          setUserAssignments(response.data);
        })
        .catch((error) => {
          console.log("Error getting assignments information", error);
        })
    }
    getUserAssignedProjects(userData.employeeID);
  }, [userData])

  useEffect(() => {
    if (email) {
      if (location.pathname !== "/tasks") {
        getTasks(email);
      }

      // Don't preload subcontractors in Test environment or when on the taskdetails page
      if (!isTest && !location.pathname.includes("/taskdetails")) {
        getSubcontractors();
      }
    }
  }, [email, isTest, location])

  useEffect(() => {
    if(
      !projectNumber
      || !userAssignments
      || userData.employeeID === 0
      || projectList.defaultProjectID === noProjectID
    ) {
      return;
    }
    // using variable to "rename" the data to make it a little more consisant to the vernacular
    const userJobCode = userData.jobCode; // ex. "BFM350"
    const companyID = projectNumber; // ex. 68457
    const projectJobNumber = projectList.projects?.find(p => p.projectID === companyID)?.projectNumber ?? "none"; // ex. "978"
    const jobNumber = userData.businessUnit; // ex. "978"

    const canEditAnyProjectsDOTCoordinator = (jobCode: string) => DOTCoordinatorJobCodes.includes(jobCode);
    const canEditTheirProjectDOTCoordinator = (jobCode: string) => DOTEditorJobCodes.includes(jobCode);
    const isPMSM = (jobCode: string) => PMSMJobCodes.includes(jobCode);
    const isPMSMProject = (jobNumber: string) => userAssignments.includes(jobNumber);
    const isInTestGroup = () => testGroups?.some(x => (x.group === 'Project_Manager' || x.group === 'Site_Manager')) ?? false;

    const userCanEditDOT = (
      canEditAnyProjectsDOTCoordinator(userJobCode)
      || ((jobNumber === projectJobNumber)
        && canEditTheirProjectDOTCoordinator(userJobCode))
      || (isPMSM(userJobCode)
        && isPMSMProject(projectJobNumber))
      || (isInTestGroup()
        && isTest)
    );

    setCanEditDOT(userCanEditDOT);
  }, [projectList, projectNumber, userAssignments, userData, testGroups, isTest])

  const setProjectNumber = async (project: string) => {
    if(!project) {
      return;
    }
    setProjectNumberState(project);
  }

  const handleProjectChange = (project: string) => {
    setProjectNumber(project);
  }

  const projectDropDownComponent = () => {
    return (
      <ProjectDropDown
        projectList={projectList}
        selectedProjectHandler={handleProjectChange}
        cookieName='projects'
      />
    );
  }

  const convertEnvironmentListToProjectList = () => {
    // for now we need to cast this into an IProject type
    // this should be fixed such that the ProjectDropDown Component
    // no longer takes an IProjectList but a list of EnvironmentData
    const projects: IProject[] = !globalEnvironmentList ?  [] : globalEnvironmentList?.map(({ projectID, projectName, projectNumber }) => {
      const project: IProject = {
        projectID,
        projectName,
        projectNumber
      };
      return project
    });

    const dseProjectList: IProjectList = {
      projects,
      defaultProjectID: '',
    }
    return dseProjectList;
  }

  const dataSourceEditorDropDownComponent = () => {
    const dseProjectList: IProjectList = convertEnvironmentListToProjectList();
    return (
      <ProjectDropDown
        projectList={dseProjectList}
        selectedProjectHandler={(project) => setDseProjectNumber(project)}
        cookieName='dseProject'
      />
    );
  }

  const getUser = ({
    email,
    jobCode,
    adGroups,
    ...otherData
  }: IDataSourceEmployeeInfo) => {
    const user = {
      email,
      jobCode: (!useTestUser ? jobCode : testJobCode),
      adGroups: (!useTestUser ? adGroups : testGroups ? testGroups.map((group) => group.id) : []),
      ...otherData,
    };
    return (user)
  }

  const setJobCode = (jobCode: string) => {
    setTestJobCode(jobCode)
  }

  return (
    <div className="App">
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
          <NavbarTop userData={userData} gettingUserData={gettingUserData} />
          <div className='d-flex justify-content-center'>
            <Switch>
              <Route exact path="/" component={(props: any) => <Dashboard {...props} updateProject={handleProjectChange} />} />
              <Route path="/dashboard" component={(props: any) => <Dashboard {...props} updateProject={handleProjectChange} />} />
              <Route path="/dashboardTaskDetails" component={(props: any) => <DashboardTaskDetails {...props} />} />
              {hasAnyAdminAccess(userData)
                && (<Route exact path="/admin"
                  component={() => (
                    <AdminPage
                      preloadedGroups={testGroups}
                      updateTestGroups={setTestGroups}
                      currentTestJobCode={testJobCode}
                      updateTestJobCode={setJobCode}
                      updateUseTestUser={() => setUseTestUser(!useTestUser)}
                      currentJobCode={testJobCode}
                      usingTestUser={useTestUser}
                      userData={userData}
                      projectSelector={projectDropDownComponent()}
                      selectedProject={projectNumber}
                    />
                  )}
                />
              )}
              <Route exact path="/tasks" component={() => <TasksPage userJobCode={userData.jobCode} preloadedTasks={tasks} updateTasks={updateTasks} />} />
              <Route path="/taskdetails/:query" component={(props: any) => <TaskDetailsPage {...props} />} />
              <Route path="/dotcoordinators" component={() => <DOTCoordinatorsPage canEdit={canEditDOT} updateProject={handleProjectChange} />} />
              <Route path="/qualityformapprovers" component={() => <QualityFormApproversPage updateProject={handleProjectChange} />} />
              <Route path="/projectgoals" component={() => <ProjectGoalsPage updateProject={handleProjectChange} />} />
              <Route path="/dataEditor/dataEditorHelp" component={() => (<div className='mt-4'><DataEditorHelp /></div>)} />
              <Route path="/ruleEditor/ruleEditorHelp" component={() => (<div className='mt-4'><RuleEditorHelp /></div>)} />
              <Route exact path="/userInfo"
                component={() => (
                  <UserInfoPage
                    userData={userData}
                  />
                )}
              />
              { globalEnvironmentList &&
                <Route exact path="/dataEditor"
                  component={() => (
                    <DataEditorPage
                      globalEnvironmentList={globalEnvironmentList}
                      projectSelector={dataSourceEditorDropDownComponent()}
                      selectedProject={dseProjectNumber}
                      userData={getUser(userData)}
                    />
                  )}
                />
              }
              {(hasRuleEditorAccess(userData)) &&
                <Route exact path="/ruleEditor"
                  component={() => (
                    <RuleEditorPage
                      userData={getUser(userData)}
                      projectList={convertEnvironmentListToProjectList()}
                      masterEmployeeList={masterEmployeeList}
                    />
                  )}
                />
              }
            </Switch>
          </div>
      </MsalAuthenticationTemplate>
      <ToastContainer />
    </div>
  );
}

export default App;