import { useEffect, useState } from 'react';
import { Badge, Container } from 'react-bootstrap';

import SaveButton from './SaveButton';
import CircleCheck from '../Icons/CircleCheck';
import CirclePending from '../Icons/CirclePending';
import CircleX from '../Icons/CircleX';
import { SaveStatus } from '../../enums/SaveStatus';
import { convertToDS } from '../../functions/DataSource';
import DataService from '../../services/DataService';
import DataSourceService from '../../services/DataSourceService';
import { DataSource, EditableDataSource } from '../../types/Data/DataSource';
import DataSourceEmployeeInfo from '../../types/Employee/DataSourceEmployeeInfo';
import EnvironmentData from '../../types/FormsOnFire/EnvironmentData';

enum EnvironmentSaveState {
    idle,
    pending,
    success,
    error,
};

interface DisplayEnvironmentSaveStatusProps {
    name: string,
    state: EnvironmentSaveState,
    onClick: (name:string) => void,
};

/**
 * Easy way to manage the display of the Environment and Save Status
 * @param name name of the environment that is being displayed
 * @param state controls the icon being viewed
 */
const DisplayEnvironmentSaveStatus = ({ name, state, onClick }: DisplayEnvironmentSaveStatusProps) => {
    const handleIconDisplay = () => {
        switch (state) {
            case EnvironmentSaveState.idle: return (<CirclePending size='20' animate={false} />);
            case EnvironmentSaveState.pending: return (<CirclePending size='20' animate={true} />);
            case EnvironmentSaveState.success: return (<CircleCheck size='20' />);
            case EnvironmentSaveState.error: return (<CircleX size='20' />);
        }
    };

    const handleClick = () => {
        switch (state) {
            case EnvironmentSaveState.pending: break;
            case EnvironmentSaveState.success: break;
            case EnvironmentSaveState.idle:
            case EnvironmentSaveState.error:
                onClick(name);
        }
    };

    const handlTitle = () => {
        switch (state) {
            case EnvironmentSaveState.pending: return 'Saving';
            case EnvironmentSaveState.success: return 'Save Success!';
            case EnvironmentSaveState.idle: return 'Click to Run Save';
            case EnvironmentSaveState.error: return 'Error: Click to Rerun Save';
        }
    };

    return (
        <div className='row align-items-center display-environment-save-status-name'>
            <div className='col-1'></div>
            <div className='col-8 border-bottom overflow-auto text-nowrap' title={name}>
                {name}
            </div>
            <div
                className='col-2 mb-1 pb-1 text-center border-bottom'
                onClick={handleClick}
                title={handlTitle()}
            >
                {handleIconDisplay()}
            </div>
            <div className='col-1'></div>
        </div>
    );
};

/**
 * this is used as a container to better manage the asyncronous interactions of
 *   save state that are anticipated
 */
interface EnvironmentState {
    environment: EnvironmentData,
    state: EnvironmentSaveState,
};

interface SaveGlobalDataSourceDisplayProps {
    userData: DataSourceEmployeeInfo,
    environmentsToUpdate: EnvironmentData[],
    onSaveStatusChange: (state: SaveStatus) => void,
    editableDataSource: EditableDataSource,
};

/**
 * @param userData this is used to record who saved the data source
 * @param environmentsToUpdate this is the list of EnvironmentData that we will
 *                              use to perform the save
 * @param onSaveStatusChange this is used to pass back the save status allowing
 *                            for disabiling modal closing during the save
 */
const SaveGlobalDataSourceDisplay = ({ userData, environmentsToUpdate, onSaveStatusChange, editableDataSource }: SaveGlobalDataSourceDisplayProps) => {
    const [environmentStateList, setEnvironmentStateList] = useState<EnvironmentState[]>([]);
    const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.Unsaved);
    const [saveStatusKey, setSaveStatusKey] = useState<string>(`saveStatusKey${Date.now()}`);
    const [badgeKey, setBadgeKey] = useState<string>(`badges${Date.now()}`);

    /**
     * convert the EnvironmentData[] to EnvironmentState[] on load
     */
    useEffect(() => {
        const environmentStates: EnvironmentState[] = environmentsToUpdate.map((environment) => (
            {
                environment,
                state: EnvironmentSaveState.idle,
            }
        ));
        setEnvironmentStateList(environmentStates);
    }, []);

    /**
     * this is used to consolidate actions necessary on save
     */
    const reportSaving = () => {
        onSaveStatusChange(SaveStatus.Saving);
        setSaveStatus(SaveStatus.Saving);
        setSaveStatusKey(`saveStatusKey${Date.now()}`);
    }

    /**
     * this is used to consolidate actions necessary on error
     */
    const reportError = () => {
        onSaveStatusChange(SaveStatus.Error);
        setSaveStatus(SaveStatus.Error);
        setSaveStatusKey(`saveStatusKey${Date.now()}`);
    }

    /**
     * manage the save state
     */
    const handleSaveButtonUpdate = () => {
        switch (true) {
            case environmentStateList.some(e => e.state === EnvironmentSaveState.error):
                setSaveStatus(SaveStatus.Error);
                onSaveStatusChange(SaveStatus.Error);
                break;
            case environmentStateList.some(e => e.state === EnvironmentSaveState.pending):
                setSaveStatus(SaveStatus.Saving);
                onSaveStatusChange(SaveStatus.Saving);
                break;
            case environmentStateList.every(e => e.state === EnvironmentSaveState.success):
                setSaveStatus(SaveStatus.Saved);
                onSaveStatusChange(SaveStatus.Saved);
                break;
            default:
                setSaveStatus(SaveStatus.Unsaved);
                onSaveStatusChange(SaveStatus.Unsaved);
                break;
        }
        setSaveStatusKey(`saveStatusKey${Date.now()}`);
    }

    // mock up clicking on the save/error button
    /**
     * Allow us to break out saving to individual environmentsthis is useful
     *   if users want to be able to start a save individually
     * @param name name of the environment that is being saved
     */
    const kickOffOneSave = async (name: string) => {
        // get the environment index for the given environment (by name)
        const i = environmentStateList.findIndex(env =>
            (`${env.environment.projectNumber} - ${env.environment.projectName}` === name)
        );
        // set the environments save status and force rerender
        const env = environmentStateList;

        try {
            // if the environment was not found through an error
            if (i === -1) {
                throw new Error(`couldn't find the environment ${name}`);
            }

            const initialRun = environmentStateList.every(e => e.state === EnvironmentSaveState.idle);

            // update environment status
            env[i].state = EnvironmentSaveState.pending;
            setEnvironmentStateList(env);
            setSaveStatusKey(`saveStatusKey${Date.now()}`);

            // if this is the initial run (i.e. we are coming off the idle state)
            //   backup the data source to the primary environment
            if (initialRun) {
                const primaryEnvironment = process.env.REACT_APP_PRIMARY_FOF_ENVIRONMENT as string;
                await DataSourceService.createDataSourceBackup(primaryEnvironment, editableDataSource.externalId, userData.email);
            }

            let saveDS: DataSource = convertToDS(editableDataSource);
            saveDS.companyId = Number(env[i].environment.projectID);

            await DataService.setDataSource(saveDS);
            // update the save state of this environment
            env[i].state = EnvironmentSaveState.success;

        } catch (error) {
            reportError();
            if (i !== -1) {
                env[i].state = EnvironmentSaveState.error;
            }
            console.log(`Error occurred while trying to save ${name}:`, error);
        } finally {
            setEnvironmentStateList(env);
        }
    }

    /**
     * this wills start any save actions that are not completed, allowing the
     *   save button to be pressed if/when there are any errors, so the user
     *   doesn't need to save the environements individually
     */
    const kickOffSaves = async () => {
        reportSaving();
        try {
            /**
             * here we figure out which environments need to be saved.  This
             *   is used to allow the "Save" button to be pressed again in the
             *   event of an error.  Only kicking off the saves for environments
             *   that have previously failed.
             */
            const environmentsToKickOff: EnvironmentState[] = environmentStateList.filter(e => (
                e.state === EnvironmentSaveState.idle
                || e.state === EnvironmentSaveState.error
            ));
            // wait for all the saves to complete
            await Promise.all(environmentsToKickOff.map(async (e) =>
                kickOffOneSave(`${e.environment.projectNumber} - ${e.environment.projectName}`)
            ));
        } catch (ex) {
            console.log('Error occurred while do a save all:', ex);
        } finally {
            // this will update the button value based off of individual
            //  environment save status
            handleSaveButtonUpdate();
        };
    };

    return (
        <Container>
            <div className='col'>
                <div className='row mb-2 border rounded shadow-sm'>
                    <p>
                        Caution should be taken to ensure you want to effect all Active and Warranty-Active environments.
                    </p>
                    <p>
                        When you click save you will push your current changes to the listed environments.
                        This Process should not be interrupted!
                    </p>
                </div>
                <div className='row mb-2'>
                    <div
                        className='col pb-2 text-center border-bottom'
                        title={saveStatus === SaveStatus.Error ? 'Click to Rerun Failed Saves' : ''}
                        key={saveStatusKey}
                    >
                        <SaveButton
                            saveStatus={saveStatus}
                            errorText='Errors'
                            onClick={() => {
                                kickOffSaves();
                            }}
                        />
                    </div>
                </div>
                <div className='row ms-3 mt-2' key={badgeKey}>
                    <div className='col border-end'>
                        <div className='row'>
                            <div className='col-6'>
                                Pending
                            </div>
                            <div className='col'>
                                <Badge bg='warning'>
                                    {
                                        environmentStateList.filter((e) => (
                                            e.state === EnvironmentSaveState.idle
                                            || e.state === EnvironmentSaveState.pending
                                        )).length
                                    }
                                </Badge>
                            </div>
                        </div>
                    </div>
                    <div className='col border-end'>
                        <div className='row'>
                            <div className='col-6'>
                                Success
                            </div>
                            <div className='col'>
                                <Badge bg='success'>
                                    {
                                        environmentStateList.filter((e) => (
                                            e.state === EnvironmentSaveState.success
                                        )).length
                                    }
                                </Badge>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-4'>
                                Error
                            </div>
                            <div className='col'>
                                <Badge bg='danger'>
                                    {
                                        environmentStateList.filter((e) => (
                                            e.state === EnvironmentSaveState.error
                                        )).length
                                    }
                                </Badge>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className='row my-2 py-2 border rounded shadow-sm overflow-auto data-source-global-save-body'
                >
                    {
                        environmentStateList.map(({environment, state}) => (
                            <div
                                key={`${environment.projectName}`}
                            >
                                <DisplayEnvironmentSaveStatus
                                    name={`${environment.projectNumber} - ${environment.projectName}`}
                                    state={state}
                                    onClick={async (name: string) => {
                                        /**
                                         * handle the clicking of an individual
                                         *   environment (to save)
                                         */
                                        reportSaving();
                                        await kickOffOneSave(name);
                                        handleSaveButtonUpdate();
                                    }}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </Container>
    );
};

export default SaveGlobalDataSourceDisplay;