import { MsalContext } from '@azure/msal-react';
import React from 'react';
import ITestGroup from '../types/TestGroup';
import IProjectList from '../types/Dashboard/ProjectList';
import ProjectDropdown from '../components/ProjectDropdown';
import { appInsights } from '../services/appInsights';
import DashboardService from '../services/DashboardService';
import { Fade, Modal, Spinner, Table } from 'react-bootstrap';
import Delayed from '../components/Delayed';
import ButtonType from '../components/ButtonType';
import AddButton from '../components/AddButton';
import TextField from '../components/TextField';
import DateField from '../components/DateField';
import Benchmark from '../types/ProjectGoal/Benchmark';
import BenchmarkDetail from '../types/ProjectGoal/BenchmarkDetail';
import ProjectGoalDetail from '../types/ProjectGoal/ProjectGoalDetail';
import SelectField from '../components/SelectField';
import ProjectGoalService from '../services/ProjectGoalService';
import MyPagination from '../components/MyPagination';
import LoadingWrapper from '../components/LoadingWrapper';

type Props = {
    updateProject?: (e: any) => void;
}

type State = {
    userGroups: ITestGroup[];
    projectLoading: boolean;
    selectedProject: string;
    projectList: IProjectList;
    projectGoalsLoading: boolean;
    projectGoalDetail: ProjectGoalDetail,
    benchmarkDetail: BenchmarkDetail,
    benchmarkToEdit: Benchmark,
    benchmarkBeforeEdits: Benchmark;
    sort_type: string;
    scope_sort_type: string;
    openModal: boolean;
    modalType: string;
    benchmarkTradeFilter: string;
    benchmarkActivityFilter: string;
    tierFilter: string;
    targetCompletionFilter: string;
    filteredBenchmarkLength: number;
    benchmark_page: number;
    benchmark_page_size: number;
    benchmarkIndexOfFirst: number;
    benchmarkIndexOfLast: number;
    quality_page: number;
    quality_page_size: number;
    qualityIndexOfFirst: number;
    qualityIndexOfLast: number;
    qualityTradeFilter: string;
    qualityActivityFilter: string;
    scopeFilter: string;
    goalFilter: string;
    appliesToProjectFilter: string;
    filteredQualityControlLength: number;
    changesMade: boolean;
    duplicateBenchmark: boolean;
    activityOptions: any[];
    tierOptions: any[];
    saveLoading: boolean;
    saveStatus: string;
    showSaveStatus: boolean;
}

// Constant variables for modal types
const ADDBENCHMARK = "AddBenchmark";
const EDITBENCHMARK = "EditBenchmark";
const DELETEBENCHMARK = "DeleteBenchmark";
const SAVESUCCESS = "SaveSuccess";
const SAVEERROR = "SaveError";

export default class ProjectGoalsPage extends React.Component<Props, State> {
    static contextType = MsalContext;
    constructor(props: any) {
        super(props);

        this.state = {
            userGroups: [],
            projectLoading: true,
            selectedProject: "",
            projectList: {
                projects: [],
                defaultProjectID: ""
            },
            projectGoalsLoading: false,
            projectGoalDetail: {
                projectID: "",
                benchmarkList: [],
                benchmarkDeleteList: [],
                qualityControlList: [],
                canEdit: false,
                userEmail: ""
            },
            benchmarkDetail: {
                trades: [],
                tiers: [],
                activities: []
            },
            benchmarkToEdit: {
                trade: "",
                activity: {formID: "", formName: "", trade: ""},
                tier: {formID: "", formName: "", trade: ""},
                targetCompletion: ""
            },
            benchmarkBeforeEdits: {
                trade: "",
                activity: {formID: "", formName: "", trade: ""},
                tier: {formID: "", formName: "", trade: ""},
                targetCompletion: ""
            },
            sort_type: "",
            scope_sort_type: "",
            openModal: false,
            modalType: "",
            benchmarkTradeFilter: "",
            benchmarkActivityFilter: "",
            tierFilter: "",
            targetCompletionFilter: "",
            filteredBenchmarkLength: 0,
            benchmark_page: 1,
            benchmark_page_size: 5,
            benchmarkIndexOfFirst: 0,
            benchmarkIndexOfLast: 5,
            quality_page: 1,
            quality_page_size: 10,
            qualityIndexOfFirst: 0,
            qualityIndexOfLast: 10,
            qualityTradeFilter: "",
            qualityActivityFilter: "",
            scopeFilter: "",
            goalFilter: "",
            appliesToProjectFilter: "",
            filteredQualityControlLength: 0,
            changesMade: false,
            duplicateBenchmark: false,
            activityOptions: [],
            tierOptions: [],
            saveLoading: false,
            saveStatus: "",
            showSaveStatus: false
        }
    }

    async componentDidMount() {
        appInsights.trackPageView({ name: "Project Goals Page" });
        this.getProjects();
    }

    /**
     * Gets Projects data from the DashboardService
     */
    async getProjects() {
        const userEmail = this.context.accounts[0].username;

        // Get Project data
        await DashboardService.getProjects(userEmail)
        .then((response) => {
            this.setState({
                projectLoading: false,
                projectList: response.data
            });

            // Set selectedProject state if user has a default project
            if (response.data.defaultProjectID && !this.state.selectedProject) {
                this.setState({
                    selectedProject: response.data.defaultProjectID
                });
            }

            // Call getProjectGoals
            if (this.state.selectedProject) {
                this.setState({
                    projectGoalsLoading: true
                });
                this.getProjectGoals(this.state.selectedProject);
            }
        }).catch((e) => {
            this.setState({
                projectLoading: false
            });
        });
    }

    /**
     * Gets ProjectGoals data from the ProjectGoalService
     * @param project companyID
     */
    async getProjectGoals(project: string) {
        const userEmail = this.context.accounts[0].username;

        // Get Benchmark and Quality Control data
        await ProjectGoalService.getProjectGoalDetails(project, userEmail)
        .then((response) => {
            if (response.data) {
                var newProjectGoalDetail = response.data;
                newProjectGoalDetail.userEmail = userEmail;
                this.setState({
                    projectGoalDetail: newProjectGoalDetail,
                    projectGoalsLoading: false
                });
            }
        });

        this.calculateFilteredListLength();

        // If user can edit, get BenchmarkDetails data for adding/editing Benchmark Goals
        if (this.state.projectGoalDetail.canEdit) {
            await ProjectGoalService.getBenchmarkDetails(project)
            .then((response) => {
                if (response.data) {
                    this.setState({
                        benchmarkDetail: response.data
                    });
                }
            });

            this.setTierOptions();
        }
    }

    /**
     * Saves the updates to the database tables
     */
    async saveUpdates(data: ProjectGoalDetail) {
        this.setState({
            saveLoading: true,
            changesMade: false, 
            showSaveStatus: true
        });
        await ProjectGoalService.updateProjectGoalDetails(data)
        .then((response) => {
            if (response.status >= 100 && response.status <= 399) { // Successful responses
                this.setState({
                    saveStatus: SAVESUCCESS,
                    saveLoading: false
                });
            }
            else {
                this.setState({
                    saveStatus: SAVEERROR,
                    saveLoading: false
                });
            }

            // Set showSaveStatus back to false after 10 seconds
            setTimeout(() => {this.setState({showSaveStatus: false})}, 10000)
        });

        this.setState({
            changesMade: false
        });
    }

    /**
     * Sets the options for the Activity SelectField
     */
    setActivityOptions = () => {
        var trade = this.state.benchmarkToEdit.trade;
        var newActivityOptions = this.state.activityOptions;

        // Clear activityOptions and add to list based on selected trade
        newActivityOptions = [];
        this.state.benchmarkDetail.activities.forEach((activity) => {
            if (activity.trade === trade) {
                newActivityOptions.push({value: activity.formID, label: activity.formName});
            }
        });

        this.setState({
            activityOptions: newActivityOptions
        });
    }

    /**
     * Sets the options for the Tier SelectField
     */
    setTierOptions = () => {
        var newTierOptions = this.state.tierOptions;

        // Clear tierOptions and add to list excluding TRAINING BENCHMARK TIER 2 (formID = 2202)
        newTierOptions = [];
        this.state.benchmarkDetail.tiers.forEach((tier) => {
            if (!(tier.formID === "2202")) {
                newTierOptions.push({value: tier.formID, label: tier.formName});
            }
        });

        this.setState({
            tierOptions: newTierOptions
        });
    }

    /**
     * Handles project changes in the ProjectDropdown
     * @param project companyID
     */
    handleProjectChange = (project: string) => {
        if(this.props.updateProject) {
            this.props.updateProject(project);
        }
        this.setState({
            selectedProject: project,
            projectGoalsLoading: true
        });

        // Re-call getProjectGoals for new project
        this.getProjectGoals(project);
    }

    /**
     * Handles trade changes in the Trade SelectField
     * @param trade trade string
     */
    handleTradeChange = (trade: string) => {
        var newBenchmarkToEdit = this.state.benchmarkToEdit;
        newBenchmarkToEdit.trade = trade;

        this.setState({
            benchmarkToEdit: newBenchmarkToEdit
        });

        this.setActivityOptions();

        // Check for duplicate entries
        this.checkForDuplicate();
    }

    /**
     * Check if the Activity falls within a specific subset and if it does then
     *   set the "default" Tier accordingly
     * @param activityIdValue From the "value" field of the activity ID
     */
    getBenchmarkGoalTierValue = (activityIdValue: string) => {
        switch(activityIdValue) {
            case "688": // PMT MV-Connections
            case "1319": // Transformer MV Connections
            case "2569": // Transformer MV Connections
                return "1402"; // MV TERMINATION BENCHMARCK
            case "692": // RISER TERMINATIONS
            case "1320": // RISER TERMINATIONS
            case "1433": // RISER TERMINATIONS
            case "2565": // RISER TERMINATIONS
                return "1404"; // RISER TERMINATION BENCHMARK
            case "34": // SPLICE CONDUCTOR MV
            case "693": // SPLICE CONDUCTOR
                return "1403"; // SPLICE CONDUCTOR BENCHMARK
            default:
                return "";
        }
    }

    /**
     * Handles tier changes in the Tier SelectField
     * @param formID fomrID number of the tier you are using
     */
    handleTierChange = (formID: string) => {
        var newBenchmarkToEdit = this.state.benchmarkToEdit;

        const presetTier = this.getBenchmarkGoalTierValue(this.state.benchmarkToEdit.activity.formID);
        const newFormID = presetTier ? presetTier : formID;

        var index = this.state.benchmarkDetail.tiers.findIndex(f => f.formID === newFormID)
        newBenchmarkToEdit.tier = (index === -1) ? { formID: '', formName: '', trade: '' } : this.state.benchmarkDetail.tiers[index];

        this.setState({
            benchmarkToEdit: newBenchmarkToEdit
        });

        // Check for duplicate entries
        this.checkForDuplicate();
    }

    /**
     * Handles activity changes in the Activity SelectField
     * @param formID
     */
    handleActivityChange = (formID: string) => {
        var newBenchmarkToEdit = this.state.benchmarkToEdit;
        var index = this.state.benchmarkDetail.activities.findIndex(f => f.formID === formID)
        newBenchmarkToEdit.activity = this.state.benchmarkDetail.activities[index];

        this.setState({
            benchmarkToEdit: newBenchmarkToEdit
        });

        // Check for duplicate entries
        this.checkForDuplicate();

        // this will force the Tier to look at the Activity
        this.handleTierChange("");
    }

    /**
     * Handles targetCompletion changes in the Target Completion DateField
     * @param newDate date
     */
    handleTargetCompletionChange = (newDate: string) => {
        var newBenchmarkToEdit = this.state.benchmarkToEdit;
        newBenchmarkToEdit.targetCompletion = newDate;

        this.setState({
            benchmarkToEdit: newBenchmarkToEdit
        });
    }

    /**
     * Handles goal changes in the Goal input number
     * @param formID activity formID
     * @param event goal
     */
    handleGoalChange = (formID: string, value: string) => {
        var newProjectGoalDetail = this.state.projectGoalDetail;
        var index = newProjectGoalDetail.qualityControlList.findIndex(f => f.formID === formID);

        // doing the conversion the following way will automatically eliminate leading zeros entered by the user
        const parsed = parseInt(value);
        const number = isNaN(parsed) ? 0 : parsed;
        if ((number > -1) && (number < 1000000)) {
            newProjectGoalDetail.qualityControlList[index].goal = number.toString();
            this.setState({
                projectGoalDetail: newProjectGoalDetail,
                changesMade: true
            });
        }
    }

    /**
     * Handles appliesToProject changes in the Applies to Project input checkbox
     * @param formID activity formID
     */
    handleAppliesToProjectChange = (formID: string) => {
        var newProjectGoalDetail = this.state.projectGoalDetail;
        var index = newProjectGoalDetail.qualityControlList.findIndex(f => f.formID === formID);

        // Flip the boolean value of appliesToProject for the clicked checkbox
        if (newProjectGoalDetail.qualityControlList[index].appliesToProject === false) {
            newProjectGoalDetail.qualityControlList[index].appliesToProject = true;
        } else {
            newProjectGoalDetail.qualityControlList[index].appliesToProject = false;
        }

        this.setState({
            projectGoalDetail: newProjectGoalDetail,
            changesMade: true
        });
    }

    /**
     * Handles page changes in the Pagination for the Benchmark section
     * @param newPage page number
     */
     handleBenchmarkPageChange = (newPage: number) => {
        var newIndexOfLast = newPage * this.state.benchmark_page_size;
        var newIndexOfFirst = newIndexOfLast - this.state.benchmark_page_size;

        this.setState({
            benchmark_page: newPage,
            benchmarkIndexOfLast: newIndexOfLast,
            benchmarkIndexOfFirst: newIndexOfFirst
        });
    }

    /**
     * Handles page changes in the Pagination for the Quality Control section
     * @param newPage page number
     */
    handleQualityPageChange = (newPage: number) => {
        var newIndexOfLast = newPage * this.state.quality_page_size;
        var newIndexOfFirst = newIndexOfLast - this.state.quality_page_size;

        this.setState({
            quality_page: newPage,
            qualityIndexOfLast: newIndexOfLast,
            qualityIndexOfFirst: newIndexOfFirst
        });
    }

    /**
     * Handles page_size changes in the Page Size SelectField for the Benchmark section
     * @param event page size
     */
     handleBenchmarkPageSizeChange = (event: any) => {
        const pageSize = event.target.value;
        const newLastPage = Math.ceil(this.state.filteredBenchmarkLength/pageSize);

        var currentPage = this.state.benchmark_page;

        if (newLastPage < currentPage){
            currentPage = newLastPage;
        }

        var newIndexOfLast = this.state.benchmark_page * pageSize;
        var newIndexOfFirst = newIndexOfLast - pageSize;

        this.setState({ 
            benchmark_page_size: pageSize,
            benchmark_page: currentPage,
            benchmarkIndexOfLast: newIndexOfLast,
            benchmarkIndexOfFirst: newIndexOfFirst
        });
    }

    /**
     * Handles page_size changes in the Page Size SelectField for the Quality Control section
     * @param event page size
     */
    handleQualityPageSizeChange = (event: any) => {
        const pageSize = event.target.value;
        const newLastPage = Math.ceil(this.state.filteredQualityControlLength/pageSize);

        var currentPage = this.state.quality_page;

        if (newLastPage < currentPage){
            currentPage = newLastPage;
        }

        var newIndexOfLast = this.state.quality_page * pageSize;
        var newIndexOfFirst = newIndexOfLast - pageSize;

        this.setState({ 
            quality_page_size: pageSize,
            quality_page: currentPage,
            qualityIndexOfLast: newIndexOfLast,
            qualityIndexOfFirst: newIndexOfFirst
        });
    }

    /**
     * Handles resetting the filters for the Benchmark section
     */
    handleBenchmarkResetFilters = () => {
        this.setState({
            benchmark_page: 1,
            benchmarkTradeFilter: "",
            benchmarkActivityFilter: "",
            tierFilter: "",
            targetCompletionFilter: "",
            sort_type: ""
        }, this.calculateFilteredListLength); // Callback re-calculates filtered list length for pagination
    }

    /**
     * Handles resetting the filters for the Quality Control section
     */
    handleQualityResetFilters = () => {
        this.setState({
            quality_page: 1,
            qualityTradeFilter: "",
            qualityActivityFilter: "",
            scopeFilter: "",
            goalFilter: "",
            appliesToProjectFilter: "",
            scope_sort_type: ""
        }, this.calculateFilteredListLength); // Callback re-calculates filtered list length for pagination
    }

    /**
     * Opens the modal
     * @param type type of modal
     */
    onOpenModal = (type: string) => {
        this.setState({
            openModal: true,
            modalType: type
        });
    }

    /**
     * Closes the modal
     */
    onCloseModal = () => {
        this.setState({
            benchmarkToEdit: {
                trade: "",
                activity: {formID: "", formName: "", trade: ""},
                tier: {formID: "", formName: "", trade: ""},
                targetCompletion: ""
            },
            openModal: false,
            modalType: "",
            activityOptions: [],
            duplicateBenchmark: false
        });
    }

    /**
     * Sets up the modal for editing
     * @param benchmarkGoal benchmark
     */
    onBenchmarkEdit = (benchmarkGoal: Benchmark) => {
        var newBenchmarkToEdit = {trade: benchmarkGoal.trade, activity: benchmarkGoal.activity, tier: benchmarkGoal.tier, targetCompletion: benchmarkGoal.targetCompletion};

        this.setState({
            benchmarkToEdit: newBenchmarkToEdit,
            benchmarkBeforeEdits: benchmarkGoal
        });

        this.onOpenModal(EDITBENCHMARK);
    }

    /**
     * Sets up the modal for deleting
     * @param benchmarkGoal benchmark
     */
    onBenchmarkDelete = (benchmarkGoal: Benchmark) => {
        this.setState({
            benchmarkToEdit: benchmarkGoal
        });

        this.onOpenModal(DELETEBENCHMARK);
    }

    /**
     * Sorts the Target Completion column for Benchmark by "asc" or "desc" order
     */
    sortTargetCompletionDateColumn = () => {
        var newSort;
        const currentSort = this.state.sort_type;

        if (currentSort === "asc") {
            newSort = "desc";
        }
        else if (currentSort === "desc") {
            newSort = "asc";
        }
        else {
            newSort = "asc"
        }

        this.setState({
            sort_type: newSort
        });
    }

    /**
     * Updates the Scope sorting type from a-z to z-a
     */
    updateScopeColumnSort = () => {
        var newSort;
        const currentSort = this.state.scope_sort_type;

        if (currentSort === "az") {
            newSort = "za";
        } 
        else if (currentSort === "za") {
            newSort = "az";
        }
        else {
            newSort = "az"
        }

        this.setState({
            scope_sort_type: newSort
        });
    }

    /**
     * Filters the Trade column for Benchmark by input
     * @param event filter input
     */
    filterBenchmarkTradeColumn = (event: any) => {
        this.setState({benchmarkTradeFilter: event.target.value}, this.calculateFilteredListLength); // Callback re-calculates filtered list length for pagination
    }

    /**
     * Filters the Activity column for Benchmark by input
     * @param event filter input
     */
    filterBenchmarkActivityColumn = (event: any) => {
        this.setState({benchmarkActivityFilter: event.target.value}, this.calculateFilteredListLength); // Callback re-calculates filtered list length for pagination
    }

    /**
     * Filters the Tier column for Benchmark by input
     * @param event filter input
     */
    filterTierColumn = (event: any) => {
        this.setState({tierFilter: event.target.value}, this.calculateFilteredListLength); // Callback re-calculates filtered list length for pagination
    }

    /**
     * Filters the Target Completion column for Benchmark by input
     * @param event filter input
     */
    filterTargetCompletionColumn = (event: any) => {
        this.setState({targetCompletionFilter: event}, this.calculateFilteredListLength); // Callback re-calculates filtered list length for pagination
    }

    /**
     * Filters the Trade column for Quality Control by input
     * @param event filter input
     */
    filterQualityTradeColumn = (event: any) => {
        this.setState({qualityTradeFilter: event.target.value}, this.calculateFilteredListLength); // Callback re-calculates filtered list length for pagination
    }

    /**
     * Filters the Activity column for Quality Control by input
     * @param event filter input
     */
    filterQualityActivityColumn = (event: any) => {
        this.setState({qualityActivityFilter: event.target.value}, this.calculateFilteredListLength); // Callback re-calculates filtered list length for pagination
    }

    /**
     * Filters the Scope column for Quality Control by input
     * @param event filter input
     */
    filterScopeColumn = (event: any) => {
        this.setState({scopeFilter: event.target.value}, this.calculateFilteredListLength); // Callback re-calculates filtered list length for pagination
    }

    /**
     * Filters the Goal column for Quality Control by input
     * @param event filter input
     */
    filterGoalColumn = (value: string) => {
        // doing the conversion the following way will automatically eliminate leading zeros entered by the user
        const parsed = parseInt(value);
        const number = isNaN(parsed) ? "" : parsed.toString();
        if ((number === "") || (number.length < 7)) {
            this.setState({goalFilter: number}, this.calculateFilteredListLength); // Callback re-calculates filtered list length for pagination
        }
    }

    /**
     * Filters the Applies to Project column for Quality Control by input
     * @param event filter input
     */
    filterAppliesToProjectColumn = (event: any) => {
        this.setState({appliesToProjectFilter: event.target.value}, this.calculateFilteredListLength); // Callback re-calculates filtered list length for pagination
    }

    /**
     * Checks if any of the benchmarkToEdit values are empty
     * @returns boolean
     */
    checkForEmptyBenchmarkValues() {
        return this.state.benchmarkToEdit.trade && this.state.benchmarkToEdit.activity.formID && this.state.benchmarkToEdit.tier.formID && this.state.benchmarkToEdit.targetCompletion ? false : true;
    }

    /**
     * Checks if the entry is a duplicate
     */
    checkForDuplicate() {
        var newBenchmark = this.state.benchmarkToEdit;
        var isDuplicate = false;

        this.state.projectGoalDetail.benchmarkList.forEach((entry) => {
            if (entry.trade === newBenchmark.trade && entry.activity.formID === newBenchmark.activity.formID && entry.tier.formID === newBenchmark.tier.formID) {
                isDuplicate = true;
            }
        });

        this.setState({
            duplicateBenchmark: isDuplicate
        });
    }

    /**
     * Calculates the length of the the filtered lists for both Benchmark and Quality Control
     */
    calculateFilteredListLength = () => {
        // Declaring variables
        var projectGoalDetail = this.state.projectGoalDetail;
        var benchmarkTradeFilter = this.state.benchmarkTradeFilter;
        var benchmarkActivityFilter = this.state.benchmarkActivityFilter;
        var tierFilter = this.state.tierFilter;
        var targetCompletionFilter =this.state.targetCompletionFilter;
        var qualityTradeFilter = this.state.qualityTradeFilter;
        var qualityActivityFilter = this.state.qualityActivityFilter;
        var scopeFilter = this.state.scopeFilter;
        var goalFilter = this.state.goalFilter;
        var appliesToProjectFilter = this.state.appliesToProjectFilter;

        // Getting filtered Benchmark list length
        var newFilteredBenchmarkLength = projectGoalDetail.benchmarkList
            .slice()
            .filter((entry) => entry.trade.toUpperCase().includes(benchmarkTradeFilter.toUpperCase()) &&
                            entry.activity.formName.toUpperCase().includes(benchmarkActivityFilter.toUpperCase()) &&
                            entry.tier.formName.toUpperCase().includes(tierFilter.toUpperCase()) &&
                            this.formatDate(entry.targetCompletion).includes(targetCompletionFilter ? this.formatDate(targetCompletionFilter) : ""))
            .length;

        // Getting filtered Quality Control list length
        var newFilteredQualityControlLength = projectGoalDetail.qualityControlList
            .slice()
            .filter((entry) => entry.trade.toUpperCase().includes(qualityTradeFilter.toUpperCase()) &&
                            entry.activity.toUpperCase().includes(qualityActivityFilter.toUpperCase()) &&
                            entry.scope.toUpperCase().includes(scopeFilter.toUpperCase()) &&
                            entry.goal.toUpperCase().includes(goalFilter.toUpperCase()) &&
                            (entry.appliesToProject ? "1" : "0").includes(appliesToProjectFilter === "Yes" ? "1" : appliesToProjectFilter === "No" ? "0" : ""))
            .length;

        this.setState({
            filteredBenchmarkLength: newFilteredBenchmarkLength,
            filteredQualityControlLength: newFilteredQualityControlLength
        });
    }

    /**
     * Adds a new benchmark goal to the projectGoalDetail state
     */
    addBenchmarkGoal = () => {
        var newProjectGoalDetail = this.state.projectGoalDetail;

        // Add benchmark
        newProjectGoalDetail.benchmarkList.push(this.state.benchmarkToEdit);

        this.setState({
            projectGoalDetail: newProjectGoalDetail,
            changesMade: true
        });

        this.onCloseModal();
    }

    /**
     * Updates an existing benchmark goal in the projectGoalDetail state
     */
    updateBenchmarkGoal = () => {
        var newProjectGoalDetail = this.state.projectGoalDetail;

        // Get index for the benchmark to update
        var index = newProjectGoalDetail.benchmarkList.indexOf(this.state.benchmarkBeforeEdits);
        
        // Update benchmark at the index
        newProjectGoalDetail.benchmarkList[index] = this.state.benchmarkToEdit;

        this.setState({
            projectGoalDetail: newProjectGoalDetail,
            changesMade: true
        });

        this.onCloseModal();
    }

    /**
     * Deletes a benchmark goal in the projectGoalDetail state
     */
    deleteBenchmarkGoal = () => {
        var newProjectGoalDetail = this.state.projectGoalDetail;

        // Get index for the benchmark to delete
        var index = this.state.projectGoalDetail.benchmarkList.indexOf(this.state.benchmarkToEdit);
       
        // Delete benchmark at the index
        newProjectGoalDetail.benchmarkList.splice(index, 1);
        newProjectGoalDetail.benchmarkDeleteList.push(this.state.benchmarkToEdit);

        this.setState({
            projectGoalDetail: newProjectGoalDetail,
            changesMade: true
        });

        this.onCloseModal();
    }

    /**
     * Formats a date and returns it
     * @param date date to format
     * @returns formatted date
     */
    formatDate = (date: string) => {
        var newDate = new Date(date.split('T')[0].replace('-', '/'));
        return ("0" + (newDate.getMonth() + 1)).slice(-2) + '/' + ("0" + newDate.getDate()).slice(-2) + '/' + newDate.getFullYear();
    }

    /**
     * Sorts the comparison between two dates based on sort_type
     * @param date1 first date
     * @param date2 second date
     * @returns boolean
     */
    sortDateResult = (date1: string, date2: string) => {
        if (this.state.sort_type === "asc") {
            if (new Date(date1) > new Date(date2)) {
                return 1;
            }
            else if (new Date(date1) < new Date(date2)) {
                return -1;
            }
            else {
                return 0;
            }
        }
        else if (this.state.sort_type === "desc") {
            if (new Date(date1) < new Date(date2)) {
                return 1;
            }
            else if (new Date(date1) > new Date(date2)) {
                return -1;
            }
            else {
                return 0;
            }
        }
        else {
            return 0;
        }
    }

    /**
     * Sorts alphabetically between two string entries on scope_sort_type
     *   default is A to Z
     * @param a first string value
     * @param b second string value
     * @param reverse true = sort Z to A; false = of A to Z (defaults to false)
     * @returns interger (-1, 0, 1)
     */
    sortAlphabetically = (a: string, b: string, reverse: boolean = false) => {
        const sort_modifier = reverse ? -1 : 1;
        const value = (a < b) ? -1 : (b < a) ? 1 : 0;
        return value * sort_modifier;
    }

    render() {
        const {
            projectLoading,
            selectedProject,
            projectList,
            projectGoalsLoading,
            projectGoalDetail,
            benchmarkDetail,
            benchmarkToEdit,
            sort_type,
            scope_sort_type,
            openModal,
            modalType,
            benchmarkTradeFilter,
            benchmarkActivityFilter,
            tierFilter,
            targetCompletionFilter,
            filteredBenchmarkLength,
            benchmark_page,
            benchmark_page_size,
            benchmarkIndexOfFirst,
            benchmarkIndexOfLast,
            quality_page,
            quality_page_size,
            qualityIndexOfFirst,
            qualityIndexOfLast,
            qualityTradeFilter,
            qualityActivityFilter,
            scopeFilter,
            goalFilter,
            appliesToProjectFilter,
            filteredQualityControlLength,
            changesMade,
            duplicateBenchmark,
            activityOptions,
            tierOptions,
            saveLoading,
            saveStatus,
            showSaveStatus
        } = this.state;

        const BenchmarkItems = projectGoalsLoading ?
            <tr>
                <td colSpan={100}>
                    <Delayed waitBeforeShow={750}>
                        <div className="DivPadded DivCenter"><Spinner animation="border" role="status" variant="primary" /></div>
                    </Delayed>
                </td>
            </tr>:
            projectGoalDetail.benchmarkList.length > 0 ? 
                projectGoalDetail.benchmarkList
                .slice() // Slice with no parameters creates a copy of the list... Used so that the original list is not manipulated by filtering and sorting
                .reverse() // Reversed so that entries are default sorted from newest to oldest
                .sort((a, b) => this.sortDateResult(a.targetCompletion, b.targetCompletion))
                .filter((entry) => entry.trade.toUpperCase().includes(benchmarkTradeFilter.toUpperCase()) &&
                                entry.activity.formName.toUpperCase().includes(benchmarkActivityFilter.toUpperCase()) &&
                                entry.tier.formName.toUpperCase().includes(tierFilter.toUpperCase()) &&
                                this.formatDate(entry.targetCompletion).includes(targetCompletionFilter ? this.formatDate(targetCompletionFilter) : ""))
                .slice(benchmarkIndexOfFirst, benchmarkIndexOfLast)
                .map((entry) =>
                <tr key={''.concat(entry.trade, entry.activity.formID, entry.tier.formID)}>
                    <td>{entry.trade}</td>
                    <td>{entry.activity.formName}</td>
                    <td>{entry.tier.formName}</td>
                    <td>{this.formatDate(entry.targetCompletion)}</td>
                    <td>
                        {projectGoalDetail.canEdit ?
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="EditButton" onClick={() => this.onBenchmarkEdit(entry)}><u>Edit</u></div>
                                <ButtonType dataName="removeBenchmark" buttonText="x" color="red" size="sm" eventHandler={() => this.onBenchmarkDelete(entry)} />
                            </div>
                        : null}
                    </td>
                </tr>
                ) :
                <tr>
                    <td colSpan={100}>
                        <b>No Benchmark Goals Found</b>
                    </td>
                </tr>

        const QualityControlItems = projectGoalsLoading ?
            <tr>
                <td colSpan={100}>
                    <Delayed waitBeforeShow={750}>
                        <div className="DivPadded DivCenter"><Spinner animation="border" role="status" variant="primary" /></div>
                    </Delayed>
                </td>
            </tr>:
            projectGoalDetail.qualityControlList.length > 0 ?
                projectGoalDetail.qualityControlList
                .sort((a, b) => {
                    if (scope_sort_type) {
                        return this.sortAlphabetically(a.scope, b.scope, (scope_sort_type === "za"));
                    } else {
                        return this.sortAlphabetically(a.trade, b.trade);
                    }
                })
                .filter((entry) => entry.trade.toUpperCase().includes(qualityTradeFilter.toUpperCase()) &&
                                entry.activity.toUpperCase().includes(qualityActivityFilter.toUpperCase()) &&
                                entry.scope.toUpperCase().includes(scopeFilter.toUpperCase()) &&
                                entry.goal.toUpperCase().includes(goalFilter.toUpperCase()) &&
                                (entry.appliesToProject ? "1" : "0").includes(appliesToProjectFilter === "Yes" ? "1" : appliesToProjectFilter === "No" ? "0" : ""))
                .slice(qualityIndexOfFirst, qualityIndexOfLast)
                .map((entry) =>
                    <tr key={''.concat(entry.trade, entry.activity, entry.scope)}>
                        <td>{entry.trade}</td>
                        <td>{entry.activity}</td>
                        <td>{entry.scope}</td>
                        <td>
                            {projectGoalDetail.canEdit ? 
                                <input className="Value form-control input-sm" style={{width: 95, height: 26}} value={entry.goal} onChange={(e) => this.handleGoalChange(entry.formID, e.target.value)} />
                            : entry.goal}
                            
                        </td>
                        <td>
                            <input type="checkbox" checked={entry.appliesToProject} disabled={!projectGoalDetail.canEdit} onChange={() => this.handleAppliesToProjectChange(entry.formID)} />
                        </td>
                    </tr>
                ) :
                <tr>
                    <td colSpan={100}>
                        <b>No Entries Found</b>
                    </td>
                </tr>

        return(
            <div className='w-75'>
            <LoadingWrapper showLoading={projectLoading} loadingMessage="Getting Project Goals..." windSpinner windSpinnerScale={3}>
                <Modal show={openModal} onHide={this.onCloseModal} centered>
                    {modalType === ADDBENCHMARK ?
                    <div>
                        <Modal.Header closeButton>
                            <Modal.Title>New Benchmark Goal:</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <SelectField dataName="newTradeSelect" title="Trade" onchange={(e) => this.handleTradeChange(e.target.value)} options={benchmarkDetail.trades.map((trade) => { return {value: trade} })} value={benchmarkToEdit.trade} required />
                                <SelectField dataName="newActivitySelect" title="Activity" onchange={(e) => this.handleActivityChange(e.target.value)} options={activityOptions} value={benchmarkToEdit.activity.formID} required />
                                <SelectField dataName="newTierSelect" title="Tier" onchange={(e) => this.handleTierChange(e.target.value)} options={tierOptions} value={benchmarkToEdit.tier.formID} required />
                                <DateField dataName="newTargetCompletionSelect" title="Target Completion" hintText="Only current or future dates are allowed." onchange={this.handleTargetCompletionChange} value={benchmarkToEdit.targetCompletion} restrictPreviousDate required />
                                <br />
                                {duplicateBenchmark ? 
                                    <div>
                                        <b>This Benchmark Goal already exists.</b>
                                        <br />
                                        <br />
                                    </div>
                                : null}
                                <div className="DivCenter">
                                    <ButtonType dataName="add" buttonText="Add" color="blue" readOnly={this.checkForEmptyBenchmarkValues() || duplicateBenchmark} eventHandler={this.addBenchmarkGoal} />
                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                    : null}
                    {modalType === EDITBENCHMARK ?
                    <div>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Benchmark Goal:</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <DateField dataName="editTargetCompletionSelect" title="Target Completion" hintText="Only current or future dates are allowed." onchange={this.handleTargetCompletionChange} value={benchmarkToEdit.targetCompletion} restrictPreviousDate required />
                                <br />
                                <div className="DivCenter">
                                    <ButtonType dataName="save" buttonText="Update" color="blue" readOnly={this.checkForEmptyBenchmarkValues()} eventHandler={this.updateBenchmarkGoal} />
                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                    : null}
                </Modal>
                {modalType === DELETEBENCHMARK ?
                    <div>
                        <Modal size="xl" show={openModal} onHide={this.onCloseModal} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Are you sure you want to delete this goal?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{width: 175}}>Trade</th>
                                            <th style={{width: 200}}>Activity</th>
                                            <th style={{width: 175}}>Tier</th>
                                            <th style={{width: 50}}>Target Completion</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{benchmarkToEdit.trade}</td>
                                            <td>{benchmarkToEdit.activity.formName}</td>
                                            <td>{benchmarkToEdit.tier.formName}</td>
                                            <td>{new Date(benchmarkToEdit.targetCompletion).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit'})}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="DivCenter">
                                    <ButtonType dataName="confirmDelete" buttonText="Yes" color="blue" eventHandler={this.deleteBenchmarkGoal} />
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                : null}

                <ProjectDropdown projectList={projectList} cookieName='projects' selectedProjectHandler={this.handleProjectChange} />

                {selectedProject ?
                    <div>
                        <div id="RightAlignedFloatDiv">
                        {projectGoalDetail.canEdit ?
                            changesMade ?
                                <ButtonType dataName="save" color="blue" buttonText="Save" type="submit" eventHandler={() => this.saveUpdates(projectGoalDetail)} />
                            : saveLoading ? 
                                <div className="DivPadded DivCenter"><Spinner animation="border" role="status" variant="primary" /></div>
                            : saveStatus === SAVESUCCESS ?
                                <div className="DivPadded DivCenter">
                                    <Fade in={showSaveStatus} >
                                        <b>Save Successful ✔</b>
                                    </Fade>
                                </div>
                            : saveStatus === SAVEERROR ?
                                <div className="DivPadded DivCenter">
                                    <b>An Error Occurred ⚠️</b><p><small><i>Please Refresh and Try Again</i></small></p>
                                </div>
                            : null
                        : null}
                        </div>
                        <h1 className="header mt-5 mb-4 text-center">Benchmark</h1>
                        {projectGoalDetail.canEdit ?
                            <AddButton dataName="Benchmark" title="Benchmark Goal" eventHandler={() => this.onOpenModal(ADDBENCHMARK)} />
                        : null}
                        <div className="d-flex justify-content-start align-items-center">
                            <b><label htmlFor="pageSize">Page Size:</label></b>
                            <div className="DivPadded">
                                <SelectField
                                    dataName="pageSize"
                                    title=""
                                    options={[{value: "5"}, {value: "10"}, {value: "20"}]}
                                    value={benchmark_page_size.toString()}
                                    onchange={this.handleBenchmarkPageSizeChange} />      
                            </div>
                            {benchmarkTradeFilter || benchmarkActivityFilter || tierFilter || targetCompletionFilter || sort_type ?
                                <ButtonType dataName="filterReset" buttonText="Reset Filters" eventHandler={() => this.handleBenchmarkResetFilters()} /> 
                            : null}
                        </div>
                        <div className="d-flex flex-wrap align-items-center">
                            <div className="p2">
                                <MyPagination currentPage={benchmark_page} pages={Math.ceil(filteredBenchmarkLength/benchmark_page_size)} onPageChange={(newPage) => this.handleBenchmarkPageChange(newPage)} />
                            </div>
                        </div>
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{width: 200}}>Trade</th>
                                    <th style={{width: 245}}>Activity</th>
                                    <th style={{width: 150}}>Tier</th>
                                    <th className="ColumnSort" onClick={this.sortTargetCompletionDateColumn} style={{width: 5}}>Target Completion {sort_type === "" ? " ⮃" : sort_type === "asc" ? " ⭡" : " ⭣"}</th>
                                    <th style={{width: 5}}></th>
                                </tr>
                                <tr key="benchmarkFilters">
                                    <td><TextField dataName="benchmarkTradeFilter" title="" value={benchmarkTradeFilter} onchange={this.filterBenchmarkTradeColumn} placeholder="filter trade" /></td>
                                    <td><TextField dataName="benchmarkActivityFilter" title="" value={benchmarkActivityFilter} onchange={this.filterBenchmarkActivityColumn} placeholder="filter activity" /></td>
                                    <td><TextField dataName="tierFilter" title="" value={tierFilter} onchange={this.filterTierColumn} placeholder="filter tier" /></td>
                                    <td><DateField dataName="targetCompletionFilter" title="" value={targetCompletionFilter} onchange={this.filterTargetCompletionColumn} /></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {BenchmarkItems}
                            </tbody>
                        </Table>

                        <br />
                        <br />

                        <h1 className="header mt-5 mb-4 text-center">Quality Control</h1>
                        <div className="d-flex justify-content-start align-items-center">
                            <b><label htmlFor="pageSize">Page Size:</label></b>
                            <div className="DivPadded">
                                <SelectField
                                    dataName="pageSize"
                                    title=""
                                    options={[{value: "10"}, {value: "25"}, {value: "50"}]}
                                    value={quality_page_size.toString()}
                                    onchange={this.handleQualityPageSizeChange} />      
                            </div>
                            {qualityTradeFilter || qualityActivityFilter || scopeFilter || goalFilter || appliesToProjectFilter || scope_sort_type ?
                                <ButtonType dataName="filterReset" buttonText="Reset Filters" eventHandler={() => this.handleQualityResetFilters()} /> 
                                : null}
                        </div>
                        <div className="d-flex flex-wrap align-items-center">
                            <div className="p2">
                                <MyPagination currentPage={quality_page} pages={Math.ceil(filteredQualityControlLength/quality_page_size)} onPageChange={(newPage) => this.handleQualityPageChange(newPage)} />
                            </div>
                            <div className="flex-grow-1 flex-wrap DivRight"><p><i>Note: This data is refreshed nightly</i></p></div>
                        </div>
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{width: 125}}>Trade</th>
                                    <th style={{width: 180}}>Activity</th>
                                    <th className="ColumnSort" onClick={this.updateScopeColumnSort} style={{width: 170}}>Scope {scope_sort_type === "" ? " ⮃" : scope_sort_type === "az" ? " ⭡" : " ⭣"}</th>
                                    <th style={{width: 50}}>Goal</th>
                                    <th style={{width: 75}}>Applies to Project</th>
                                </tr>
                                <tr key="qualityControlFilters">
                                    <td><TextField dataName="qualityTradeFilter" title="" value={qualityTradeFilter} onchange={this.filterQualityTradeColumn} placeholder="filter trade" /></td>
                                    <td><TextField dataName="qualityActivityFilter" title="" value={qualityActivityFilter} onchange={this.filterQualityActivityColumn} placeholder="filter activity" /></td>
                                    <td><TextField dataName="scopeFilter" title="" value={scopeFilter} onchange={this.filterScopeColumn} placeholder="filter scope" /></td>
                                    <td><TextField dataName="goalFilter" title="" value={goalFilter} onchange={(e) => this.filterGoalColumn(e.target.value)} placeholder="filter goal" /></td>
                                    <td><SelectField dataName="appliesToProjectFilter" title="" value={appliesToProjectFilter} onchange={this.filterAppliesToProjectColumn} options={[{value: "Yes"}, {value: "No"}]} allowSelectOption={true} /></td>
                                </tr>
                            </thead>
                            <tbody>
                                {QualityControlItems}
                            </tbody>
                        </Table>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    : <h1 className="header mt-5 mb-4 text-center">Project Goals</h1>}
            </LoadingWrapper>
            </div>
            
        );
    }
}